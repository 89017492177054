export default {
	state: {
		status: false,
		accessToken: ''
	},
	mutations: {
		setStatus(state, value) {
			state.status = value;
		},
		setAccessToken(state, accessToken) {
			state.accessToken = accessToken;
		},
	},
	actions: {
		logIn(context, accessToken) {
			context.commit('setStatus', true);
			context.commit('setAccessToken', accessToken);
		},
		logOut(context) {
			context.commit('setStatus', false);
			context.commit('setAccessToken', '');
		},
	},
	modules: {},
};
