<template>
  <v-card width="350px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: red; text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px">mdi-alert</v-icon>
        Connection Error
      </span>
    </v-card-title>
    <v-col class="pa-6">
      <v-card-text class="pa-0 mb-6" color="black">
        <span style="font-family: Inter; font-weight: 400">
          You are not connected to an ingestor. Please check your internet
          connection and try again.
        </span></v-card-text
      >
      <div style="text-align: right">
        <v-btn
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn"
          v-on:click="$emit('cancel')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Ignore</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          color="black"
          class="rounded-md ml-4 white--text"
          v-on:click="callLogIn"
        >
          <v-icon class="mr-2">mdi-reload</v-icon>
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Retry</span
          >
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import loginService from "../../../services/loginService";

export default {
  mounted() {},
  data() {
    return {
      password: "",
      error: "",
    };
  },
  methods: {
    ...mapActions(["logIn"]),
    async callLogIn() {
      let result = await loginService.login(this.password);
      if (result.success) {
        this.logIn(result.accessToken);
        this.$emit("confirm");
      } else {
        this.error = "The password you entered is incorrect";
      }
    },
  },
};
</script>

<style>
</style>