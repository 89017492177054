<template>
    <span>{{ version }}</span>
</template>

<script>
import { mapState } from "vuex";

export default  {
    computed: {
        ...mapState({
            version: (state) => state.version.version,
        })
    }
}
</script>
