<template>
  <v-container class="pa-6">
    <v-row class="mb-4">
      <v-col v-for="notification in thisChainNotifications" v-bind:key="notification.id" cols="12" sm="12" md="6" xl="4">
        <notification v-bind:notification="notification" v-on:editAlert="editAlert"/>
      </v-col>
    </v-row>
    <v-row align="center" style="margin-top: -12px">
      <v-col cols="auto" class="pr-0">
        <v-img
          min-height="24"
          max-width="24"
          contain
          v-bind:src="this.icon"
          style="margin-bottom: 2px; margin-top: -2px"
        ></v-img>
      </v-col>
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span
            style="
              font-family: Inter;
              text-transform: uppercase;
              font-weight: 600;
            "
          >
            {{ chain?.name }}
          </span>
        </div>
      </v-col>
      <chain-b-p-bar v-if="blockProducer" v-bind:blockProducer="blockProducer" />
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-chip v-if="thisActiveNodes > 0" light label outlined class="rounded-pill"
          style="height: 36px; background-color: hsl(120, 67%, 94%) !important; color: hsl(120, 67%, 39%) !important; border-color: hsl(120, 67%, 39%) !important;">
          <v-icon color="hsl(120, 67%, 39%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(120, 67%, 39%)">
            {{ thisActiveNodes }} Active
          </span>
        </v-chip>
        <v-chip v-if="thisErrorsNodes > 0" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(40, 87%, 94%) !important; color: hsl(40, 87%, 49%) !important; border-color: hsl(40, 87%, 49%) !important;">
          <v-icon color="hsl(40, 87%, 49%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(40, 87%, 49%)">
            {{ thisErrorsNodes }} With Errors
          </span>
        </v-chip>
        <v-chip v-if="thisDisconnectedNodes > 0" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(0, 87%, 94%) !important; color: hsl(0, 87%, 59%) !important; border-color: hsl(0, 87%, 59%) !important;">
          <v-icon color="hsl(0, 87%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 87%, 59%)">
            {{ thisDisconnectedNodes }} Disconnected
          </span>
        </v-chip>
        <v-chip v-if="thisNotConnectedNodes > 0" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(0, 0%, 94%) !important; color: hsl(0, 0%, 59%) !important; border-color: hsl(0, 0%, 59%) !important;">
          <v-icon color="hsl(0, 0%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 0%, 59%)">
            {{ thisNotConnectedNodes }} Not Connected
          </span>
        </v-chip>
      </v-col>
      <v-col v-if="authStatus" cols="auto" class="pl-0">
        <v-btn
          v-on:click="$emit('editChain', chain.chain_id)"
          icon
          outlined
          color="grey"
          style="background-color: rgba(0, 0, 0, 0.08)"
        >
          <v-icon small>mdi-cog</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="pt-0">
        <chain-table
          v-for="table in tables"
          v-bind:key="`${table.chain}${table.module}`"
          v-bind:table="table"
          v-bind:chain="chain"
          v-on:editChainTable="editChainTable"
          class="mt-6"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Notification from "../components/Notifications/Notification.vue"
import ChainTable from "../components/Chain/ChainTable.vue";
import ChainBPBar from "../components/Chain/ChainBPBar.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    ChainTable,
    ChainBPBar,
    Notification
  },
  data: () => ({
    copied: false,
    overlayMode: null,
    overlay: false,
  }),
  computed: {
    ...mapGetters(["getChainTables"]),
    authStatus() {
      return this.$store.state.auth.status;
    },
    name() {
      return this.chain ? this.chain.name : "";
    },
    blockProducer() {
      return this.chain
        ? this.chain.nodes.filter((node) => {
            return node.type == 0;
          })[0]
        : null;
    },
    chainId() {
      let result = this.$route.params.chain;
      return result;
    },
    chain() {
      let result = this.$store.state.chain.chains.filter(
        (chain) => chain.id == this.chainId
      )[0];
      return result;
    },
    icon() {
      return this.chain ? this.chain.icon : "";
    },
    nodes() {
      return this.chain ? this.chain.nodes : null;
    },
    tables() {
      return this.chain ? this.getChainTables(this.chain) : [];
    },
    thisChainNotifications() {
      return this.chain ? this.chainNotifications(this.chain) : null;
    },
    thisActiveNodes() {
      return this.chain ? this.activeNodes(this.chain) : 0;
    },
    thisDisconnectedNodes() {
      return this.chain ? this.disconnectedNodes(this.chain) : 0;
    },
    thisErrorsNodes() {
      return this.chain ? this.errorsNodes(this.chain) : 0;
    },
    thisNotConnectedNodes() {
      return this.chain ? this.notConnectedNodes(this.chain) : 0;
    },
    Nodes() {
      return this.nodes
        ? this.nodes.filter((node) => this.nodeStatus(node) == "disconnected")
            .length
        : 0;
    },
  },
  methods: {
    getChainId() {
      return this.$route.params.chain;
    },
    getChain() {
      return this.$store.state.chain.chains.filter(
        (chain) => chain.id == this.getChainId()
      )[0];
    },
    editChainTable(table) {
      this.$emit("editChainTable", table);
    },
    editAlert(e) {
      this.$emit('editAlert', e)
    }
  },
};
</script>

<style>
</style>
