<template>
  <div>
    <v-icon
      v-bind:color="thisNodeStatusColor"
      size="small"
      class="mr-1">
      mdi-circle
    </v-icon>
    <span
      style="
          font-family: Inter;
          font-size: 0.875rem;
          font-weight: 400;
          text-transform: capitalize;
      ">{{ thisNodeStatus }}</span>
  </div>
</template>

<script>
export default {
  props: {
    node: Object,
  },
  computed: {
    thisNodeNotifications() {
      return this.nodeNotifications(this.node);
    },
    thisNodeStatus() {
      return this.nodeStatus(this.node);
    },
    thisNodeStatusColor() {
      return this.nodeStatusForegroundColor(this.thisNodeStatus);
    },
  }
};
</script>

<style></style>