<template>
    <v-list-item class="px-4 mb-0">
        <v-list-item-content class="py-0">
            <div style="width:100%; display:flex; justify-content:space-between; align-items:center;">
                <v-list-item-title>
                    <span style="font-family:Inter; font-weight:600;">{{ title }}: </span>
                    <span style="font-family:Inter; font-weight:500;">Alert me when {{ prefix }}</span>
                    <v-text-field class="conditionField" v-if="type == 'string'" v-bind:placeholder="defaultValue"
                        v-bind:value="conditionValue" v-on:change="updateAlertCondition" hide-details outlined dense light
                        color="black" type="text" style="display:inline-block; width:120px; margin:2px 6px!important;" />
                    <v-text-field v-else-if="hasCondition" v-bind:placeholder="defaultValue" v-bind:value="conditionValue"
                        v-on:change="updateAlertCondition" hide-details outlined dense light color="black" type="number"
                        style="display:inline-block; width:80px; margin:2px 6px!important;" />
                    <span style="font-family:Inter; font-weight:500;">{{ [units,
                        suffix].filter(str => str != "").join('') }}.</span>
                </v-list-item-title>
                <div>
                    <v-chip v-on:click="toggleAlert" light label outlined
                        v-bind:color="active ? 'green green--text' : 'grey grey--text'" class="rounded-pill px-2 py-0"
                        v-bind:style="active ? 'background-color:#eaf6ea!important; cursor:pointer;' : 'background-color:#f0f0f0!important; cursor:pointer;'">
                        {{ active ? 'Alerting' : 'Alert' }}
                    </v-chip>

                </div>
            </div>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: ['connectsAlert'],
    computed: {
        active() {
            return this.connectsAlert.alert.condition != "";
        },
        title() {
            return `${this.connectsAlert.moduleMetric.Title} (${this.connectsAlert.alert.url})`;
        },
        prefix() {
            return this.connectsAlert.moduleMetric.Condition_Prefix;
        },
        suffix() {
            return this.connectsAlert.moduleMetric.Condition_Suffix;
        },
        hasCondition() {
            return this.connectsAlert.moduleMetric.Has_Condition
        },
        type() {
            return this.connectsAlert.moduleMetric.Condition_Type
        },
        units() {
            return this.connectsAlert.moduleMetric.Condition_Units
        },
        defaultValue() {
            return this.connectsAlert.moduleMetric.Condition_Default
        },
        conditionValue() {
            return this.connectsAlert.alert.condition
        },
        description() {
            return this.connectsAlert.moduleMetric.Description;
        }
    },
    methods: {
        toggleAlert() {
            if (this.connectsAlert.moduleMetric.Has_Condition) {
                this.$emit('update', this.connectsAlert.alert.id, this.active ? "" : this.defaultValue);
            } else {
                this.$emit('update', this.connectsAlert.alert.id, this.active ? "" : "on");
            }
        },
        updateAlertCondition(value) {
            this.$emit('update', this.connectsAlert.alert.id, value);
        }
    },
}
</script>