'use strict';

let informationMetrics = [
	{
		module: "Node",
		name: "Chain_Id"
	},
	{
		module: "Node",
		name: "Server_Version"
	},
	{
		module: "AtomicAssets",
		name: "Version"
	},
	{
		module: "AtomicAssets",
		name: "AssetsVersion"
	},
	{
		module: "AtomicAssets",
		name: "MarketVersion"
	},
	{
		module: "AtomicAssets",
		name: "ToolsVersion"
	},
	{
		module: "AtomicAssets",
		name: "PostgresStatus"
	},
	{
		module: "AtomicAssets",
		name: "RedisStatus"
	},
	{
		module: "AtomicAssets",
		name: "ChainStatus"
	},
	{
		module: "Hyperion",
		name: "Version"
	},
	{
		module: "Hyperion",
		name: "StreamingFeatures"
	},
	{
		module: "Hyperion",
		name: "TableFeatures"
	},
	{
		module: "Hyperion",
		name: "IndexFeatures"
	},
	{
		module: "Hyperion",
		name: "TrxFeatures"
	},
	{
		module: "Hyperion",
		name: "ResourceFeatures"
	},
	{
		module: "Hyperion",
		name: "NodeosRPCStatus"
	},
	{
		module: "Hyperion",
		name: "RabbitMqStatus"
	},
	{
		module: "Hyperion",
		name: "ElasticSearchStatus"
	}
]

let endpointMetrics = [
	{
		module: "Node",
		name: "Connects"
	},
	{
		module: "Hyperion",
		name: "Connects"
	},
	{
		module: "AtomicAssets",
		name: "Connects"
	},
]

export function isInformationMetric(module, name) {
	const searchResults = informationMetrics.filter((metric) => {
		return metric.module == module && metric.name == name;
	})
	return searchResults.length > 0;
}

export function isEndpointMetric(module, name) {
    const searchResults = endpointMetrics.filter((metric) => {
        return metric.module === module && metric.name === name;
    });
    return searchResults.length > 0;
}