export default {
	state: {
		types: {
			Node: {
				title: 'Nodes',
				colour: 'hsl(221, 90%, 50%)',
				includesNodeTypes: [0, 1],
				defaultMetrics: [
					'Head_Block',
					'Server_Version',
				]
			},
			Hyperion: {
				title: 'Hyperion',
				colour: 'hsl(203, 57%, 50%)',
				includesNodeTypes: [2],
				defaultMetrics: [
					'NodeosRPCBlockNumber',
					'Version',
				]
			},
			AtomicAssets: {
				title: 'Atomic Assets',
				colour: 'hsl(154, 71%, 50%)',
				includesNodeTypes: [3],
				defaultMetrics: [
					'ChainHeadBlock',
					'BlocksDifference',
				]
			}
		},
		tables: [],
	},
	getters: {
		activeMetrics: (state, getters) => (table) => {
			let tableInfo = getters.getTable(table);
			let module = tableInfo.module;
			return tableInfo
				.metrics
				.map(metric => ({
					module,
					name: metric
				}))
		},
		chainTables: (state) => (chain) => {
			return state.tables.filter((table) => {
				return (
					chain ?
					chain.nodes.filter((node) => {
						return table.nodeTypes.includes(node.type);
					}).length > 0
					:
					false
				);
			});
		},
		getChainTables: (state) => (chain) => {
			return state.tables.filter((table) => {
				return (
					table.chain === chain.id &&
					chain.nodes.filter((node) => {
						return state.types[table.module].includesNodeTypes.includes(node.type);
					}).length > 0
				);
			});
		},
		getTable2: (state) => (tableId) => {
			return state.tables.filter((table) => {
				return table.id === tableId;
			})[0];
		},
		getTable: (state) => ({chain, module}) => {
			return state.tables.filter((table) => {
				return table.chain === chain && table.module === module;
			})[0];
		},
		getTableNodes: (state) => ({chain, module}) => {
			return chain.nodes.filter((node) => {
				return state.types[module].includesNodeTypes.includes(node.type);
			});
		},
		getTableMetrics: (state, getters) => ({chain, module}) => {
			let table = getters.getTable({chain, module});
			return table.metrics.map((metric) => {
				if (metric == "Address") {
					return {
						Module: module,
						Name: "Address",
						Title: "Address"
					}
				} else {
					let result = getters.getMetric({module, metric});
					if (result) return result;
				}
			});
		},
		getTableHeader: (state, getters) => ({chain, module}) => {
			let tableMetrics = getters.getTableMetrics({chain, module});
			return tableMetrics.map((metric, index) => {
				return {index, title: metric.Title};
			});
		},
		getTableTitle: (state) => (module) => {
			let type = state.types[module];
			return type ? type.title : null;
		},
		getTableColour: (state) => (module) => {
			let type = state.types[module];
			return type ? type.colour : "hsl(0, 0%, 50%)";
		},
		getTableWidth: (state, getters) => ({chain, module}) => {
			let table = getters.getTable({chain, module});
			return 360 + table.metrics.length * 180 + 90 * (table.metrics.includes("Address")) + 120 + 24;
		},
	},
	mutations: {
		createTable(state, {chain, module, metrics}) {
			if ( state.tables.filter(table => {
				return table.chain === chain && table.module === module
			}).length == 0) {
				state.tables.push({chain, module, metrics});
			}
		},
		updateTable(state, { chain, module, metrics }) {
			let tableIndex = state.tables.findIndex(table => {
				return table.chain === chain && table.module === module;
			});
			if (tableIndex !== -1) {
				let updatedTable = { chain, module, metrics };
				state.tables = [
					...state.tables.slice(0, tableIndex),
					updatedTable,
					...state.tables.slice(tableIndex + 1)
				];
			}
		},
	},
	actions: {
		createInitialTables({state, getters, commit}) {
			let chains = getters.getChains;
			for (const chain of chains) {
				for (let typeKey in state.types) {
					let type = state.types[typeKey];
					if (chain.nodes.filter((node) => {
						return type.includesNodeTypes.includes(node.type);
					}).length > 0) {
						commit('createTable', {
							chain: chain.id,
							module: typeKey,
							metrics: type.defaultMetrics,
						})
					}
				}
			}
		},
		initialiseTables(context) {
			context.dispatch('createInitialTables');
			context.dispatch('loadTables');
			context.dispatch('saveTables');
		},
		loadTables({commit}) {
			let savedTables = JSON.parse(localStorage.getItem('tables'));
			if ( savedTables ) {
				for ( const savedTable of savedTables ) {
					commit('updateTable', savedTable);
				}
			}
		},
		saveTables({state}) {
			localStorage.setItem('tables', JSON.stringify(state.tables));
		},
	},
	modules: {},
};
