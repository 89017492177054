'use strict';

import DefaultDict from '../utils/defaultDict'

class MessageManager {
    constructor(store) {
        let self = this;
        this._handlerMap = {
            "ack": (event_data) => self._handleAck(event_data),
            "initial_state": (event_data) => self._handleInitialState(event_data),
            "alert": (event_data) => self._handleAlert(event_data),
            "update_alert": (event_data) => self._handleUpdateAlert(event_data),
            "clear_alert": (event_data) => self._handleClearAlert(event_data),
            "metric": (event_data) => self._handleMetric(event_data)
        }
        this.store = store;
    }
    receiveMessage(event_data) {
        let self = this;
        event_data.forEach((event) => {
            if (event.message in self._handlerMap) {
                // Ignore unknown messages
                let handler = this._handlerMap[event.message];
                handler(event);
            }
        });
    }
    _handleAck() {
    }
    _handleInitialState(event_data) {
        console.log("_handleInitialState event_data =", event_data)
        let alertsByNode = new DefaultDict.DefaultDict(() => []);
        event_data.alerts.forEach(alert => {
            alertsByNode[alert.node_id].push(alert);
        });
        let nodesByChain = new DefaultDict.DefaultDict(() => []);
        let nodes = event_data.nodes.map((node) => Object.assign({}, node, {alerts: alertsByNode[node.id]}));
        nodes.forEach(node => {
            nodesByChain[node.chain_id].push(node);
        });
        let chains = event_data.chains.map((chain) => Object.assign({}, chain, {nodes: nodesByChain[chain.id]}));
        this.store.dispatch('initialiseChains', chains);
        this.store.dispatch('initialiseTables');
        this.store.dispatch('initialiseIngestors', event_data.ingestors);
        this.store.dispatch('initialiseMetrics2', event_data.metrics);
        this.store.dispatch('setLastMessageId', event_data.id);
        this.store.dispatch('removeAllNotifications');
        event_data.active_alerts.forEach((alert) => { this.store.dispatch('createNotification', alert); })
        this.store.dispatch('setVersion', event_data);
    }
    _handleAlert(event_data) {
        console.log("Alert received =", event_data)
        this.store.dispatch('createNotification', event_data.data);
    }
    _handleUpdateAlert(event_data) {
        console.log("Update alert received =", event_data)
        this.store.dispatch('updateNotification', event_data.data);
    }
    _handleClearAlert(event_data) {
        console.log("Clear alert received =", event_data)
        this.store.dispatch('archiveNotifications', { source: event_data.source, metric: event_data.metric, url: event_data.url });
    }
    _handleMetric(event_data) {
        this.store.dispatch('setMetric', {alert_id: event_data.metric.alert_id, value: event_data.metric.value});
    }
}

function initizeMessageManager(store) {
    return new MessageManager(store);
}

export default { initizeMessageManager };
