<template>
    <v-card outlined>
        <v-container style="background-color: rgb(13, 85, 242);">
            <v-row>
                <v-col cols="auto"
                    class="pa-0"
                    align-self="center">
                    <v-card-title primary-title
                        style="text-align: left"
                        class="pa-6"
                        color="white">
                        <span style="
                            font-family: Inter;
                            color: white;
                            font-size: 18px;
                            line-height: 18px;
                        ">
                            <v-icon small
                                color="white"
                                style="margin-top: -3px">mdi-server-network</v-icon>
                            Ingestors
                        </span>
                    </v-card-title>
                </v-col>
                <v-spacer></v-spacer>
                <v-col v-if="authStatus"
                    cols="auto"
                    class="px-6 py-3"
                    align-self="center">
                    <v-btn @click="addNewIngestor"
                        text
                        icon
                        color="white">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="pa-0"
            style="overflow-x: auto">
            <v-container>
                <v-row class="pa-3"
                    align-content-center>
                    <div class="py-1 pl-3"
                        style="min-width: 150px; position: inline-block"
                        align-self="center">
                        <span
                            style="font-family: Inter; font-size: 0.875rem; line-height: 0.875rem; font-weight: 600;">Name</span>
                    </div>
                    <div class="py-1 px-2"
                        style="position: inline-block; min-width: 180px;"
                        align-self="center">
                        <span
                            style="font-family: Inter; font-size: 0.875rem; line-height: 0.875rem; font-weight: 600;">Key</span>
                    </div>
                </v-row>
                <v-row v-for="ingestor in ingestors"
                    :key="ingestor.id"
                    style="border-top: 0.6px solid rgba(0, 0, 0, 0.12);"
                    class="pa-3 flex-nowrap"
                    align-content-center>
                    <div class="py-1 pl-3"
                        style="min-width: 150px; position: inline-block; align-self: center; vertical-align: middle;">
                        <span style="
                            font-family: Inter;
                            font-size: 0.875rem;
                            line-height: 0.875rem;
                            font-weight: 600;
                            ">{{ ingestor.name }}</span>
                    </div>
                    <div class="py-1 px-2"
                        style="min-width: 180px; position: inline-block; align-self: center; vertical-align: middle;">
                        ************************
                    </div>
                    <v-spacer />
                    <div 
                        v-if="authStatus"
                        class="py-1 px-3"
                        style="
                        font-family: Inter;
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 400;
                        vertical-align: middle;
                        text-align: right;
                        width: 180px;
                        position: inline-block;
                        align-self: center;
                    ">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click="$emit('setIngestorAccessKey', ingestor.id)">
                                    <v-icon color="black">mdi-key-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Generate new key</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editIngestor(ingestor)">
                                    <v-icon color="black">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit ingestor</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon color="black">mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete ingestor</span>
                        </v-tooltip>
                    </div>
                </v-row>
            </v-container>
        </v-container>
    </v-card>
</template>

<script>
import { mapState } from "vuex";

import { EditDialogModeEnum } from "../../enums/editDialogModeEnum";

export default {
    methods: {
        addNewIngestor() {
            this.$emit('addNewIngestor');
        },
        editIngestor(ingestor) {
            this.$emit('editIngestor', ingestor.id)
        },
        getIngestor() {
            let ingestorId = this.getIngestorId();
            return this.$store.state.ingestors.ingestors.filter(
                (i) => i.id == ingestorId
            )[0];
        },
        getIngestorId() {
            return this.$route.params.ingestor;
        },
        openIngestorOverlay() {
            this.overlayMode = EditDialogModeEnum.Edit;
            this.overlayEditIngestor = true;
        },
        openSetIngestorAccessKeyOverlay() {
            this.overlayAccessKey = true;
        },
    },
    computed: {
        ...mapState({
            authStatus: (state) => state.auth.status,
            chains: (state) => state.chain.chains,
            ingestors: (state) => state.ingestors.ingestors,
        }),
    }
};
</script>
<style></style>