let nodeTypeDict = {
    "Block Producer": 0,
    "Nodeos Follower": 1,
    "Hyperion": 2,
    "Atomic Assets": 3,
    "Website": 4
};
let nodeTypeDictReversed = Object.assign(
    {},
    ...Object.entries(nodeTypeDict).map((kv) => ({ [kv[1]]: kv[0] }))
);

export {nodeTypeDict, nodeTypeDictReversed}
