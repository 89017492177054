<template>
  <v-app id="inspire">
    <v-app-bar
      id="tool-bar"
      app
      flat
      dark
      clipped-left
      class="pr-2 pl-1"
      v-bind:class="{ blur: overlay }"
    >
      <v-img
        src="./assets/headsup-logo-2.png"
        max-height="36"
        max-width="36"
        contain
        class="mr-2"
      ></v-img>
      <v-toolbar-title
        ><span style="font-family: Nunito; font-size: 32px"
          >Heads<span style="">Up</span></span
        ></v-toolbar-title
      >
      <v-spacer></v-spacer>

      <v-btn icon to="/alerts">
        <v-badge
          v-if="notifications.length > 0"
          overlap
          color="rgb(13, 83, 238)"
          v-bind:content="notifications.length"
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell</v-icon>
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on:click="openAuthOverlay" v-bind="attrs" v-on="on">
            <v-icon v-if="!authStatus">mdi-lock</v-icon>
            <v-icon v-if="authStatus">mdi-lock-open-variant</v-icon>
          </v-btn>
        </template>
        <span v-if="authStatus" style="font-family: Inter; font-weight: 400"
          >Logged In</span
        >
        <span v-if="!authStatus" style="font-family: Inter; font-weight: 400"
          >Logged Out</span
        >
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer
      app
      flat
      clipped
      hide-overlay
      v-bind:width="360"
      v-bind:style="{ top: $vuetify.application.top + 'px', zIndex: 4 }"
      v-bind:class="{ blur: overlay }"
      color="white"
    >
      <nav-drawer
        v-on:addNewChain="openChainOverlay"
        v-on:addNewNode="openNodeOverlay"
        v-on:addNewIngestor="openIngestorOverlay"
      />
    </v-navigation-drawer>

    <v-main style="background-color: #f0f0f0" v-bind:class="{ blur: overlay }">
      <router-view
        v-bind:ingestorConnectionError="ingestorConnectionError"
        v-on:editChainTable="openChainTableOverlay"
        v-on:editChain="openChainEditOverlay"
        v-on:editNode="openNodeEditOverlay"
        v-on:editMetricsAndAlerts="openMetricsAndAlertsOverlay"
        v-on:addNewIngestor="openIngestorOverlay"
        v-on:editIngestor="openIngestorEditOverlay"
        v-on:editAlert="openAlertEditOverlay"
        v-on:setIngestorAccessKey="openIngestorSetAccessKeyOverlay"
        v-on:editMultipleAlerts="openMutlipleAlertsEditOverlay"
      ></router-view>
    </v-main>

    <v-overlay v-bind:value="overlay" opacity="0.75">
      <auth-overlay
        v-if="overlayContent == 'auth'"
        v-on:close="overlay = false"
      />
      <chain-overlay
        :mode="overlayMode"
        :chain="chain"
        :key="chain.id"
        v-if="overlayContent == 'chain'"
        v-on:close="overlay = false"
      />
      <chain-table-overlay
        :chain="chain"
        :table="table"
        v-if="overlayContent == 'chain-table'"
        v-on:close="overlay = false"
      />
      <alert-edit-overlay
        v-if="overlayContent == 'alert'"
        v-on:close="overlay = false"
        :alertSource="alertSource"
        :alertMetric="alertMetric"
        :key="alertId"
      />
      <silence-all-alerts-overlay
        v-if="overlayContent == 'multiple-alerts'"
        v-on:close="overlay = false"
        :alertSources="alertSources"
        :alertMetrics="alertMetrics"
      />
      <node-overlay
        :mode="overlayMode"
        :node="node"
        :key="node.id"
        :chainId="nodeOverlayChainId"
        v-if="overlayContent == 'node'"
        v-on:close="overlay = false"
      />
      <metrics-and-alerts-overlay
        :mode="overlayMode"
        :node="node"
        :key="node.id"
        :chainId="node.chain_id"
        v-if="overlayContent == 'metrics-and-alerts'"
        v-on:close="overlay = false"
      />
      <ingestor-overlay
        :mode="overlayMode"
        :ingestor="ingestor"
        :key="ingestor.id"
        v-if="overlayContent == 'ingestor'"
        v-on:close="overlay = false"
      />
      <ingestor-edit-overlay
        :mode="overlayMode"
        :ingestor="ingestor"
        :key="ingestor.id"
        v-if="overlayContent == 'ingestor-edit'"
        v-on:close="overlay = false"
      />
      <ingestor-access-key-overlay
        :ingestor="ingestor"
        :key="ingestor.id"
        v-if="overlayContent == 'ingestor-set-access-key'"
        v-on:close="overlay = false"
      />
      <error-overlay
        v-if="overlayContent == 'error'"
        v-on:close="overlay = false"
      />
    </v-overlay>
    <div class="text-center">
      <should-upgrade />
    </div>
  </v-app>
</template>

<script>
import NavDrawer from "./components/App/NavDrawer.vue";
import AuthOverlay from "./components/App/AuthOverlay.vue";
import ChainOverlay from "./components/App/ChainOverlay.vue";
import ChainTableOverlay from "./components/App/ChainTableOverlay.vue";
import AlertEditOverlay from "./components/App/AlertEditOverlay.vue";
import NodeOverlay from "./components/App/NodeOverlay.vue";
import IngestorOverlay from "./components/App/IngestorOverlay.vue";
import IngestorEditOverlay from "./components/App/IngestorEditOverlay.vue";
import ErrorOverlay from "./components/App/ErrorOverlay.vue";
import MetricsAndAlertsOverlay from "./components/App/MetricsAndAlertsOverlay.vue";
import IngestorAccessKeyOverlay from "./components/App/IngestorAccessKeyOverlay.vue";
import ShouldUpgrade from "./components/App/ShouldUpgrade.vue";
import SilenceAllAlertsOverlay from "./components/App/SilenceAllAlertsOverlay.vue";

import webSocketService from "./services/websocketservice.js";
import messageService from "./services/messageService";
import sleepService from "./services/sleepService";
import loginService from "./services/loginService";
import { EditDialogModeEnum } from "./enums/editDialogModeEnum";
import { v4 as uuidv4 } from "uuid";

import { mapState, mapGetters, mapActions } from "vuex";
import * as Sentry from "@sentry/browser";

export default {
  name: "App",

  components: {
    NavDrawer,
    AuthOverlay,
    ChainOverlay,
    ChainTableOverlay,
    AlertEditOverlay,
    MetricsAndAlertsOverlay,
    NodeOverlay,
    IngestorOverlay,
    IngestorEditOverlay,
    ErrorOverlay,
    IngestorAccessKeyOverlay,
    ShouldUpgrade,
    SilenceAllAlertsOverlay,
  },
  data: () => ({
    navDrawExpanded: false,
    overlay: false,
    overlayContent: "",
    nodeOverlayChainId: null,
    loginOverlay: true,
    password: "",
    loggedIn: false,
    messageManager: null,
    overlayMode: null,
    node: null,
    chain: null,
    table: null,
    ingestorConnectionError: false,
    alertId: "",
    alertSource: "",
    alertMetric: "",
    alertSources: [],
    alertMetrics: [],
  }),
  computed: {
    ...mapState({
      authStatus: (state) => state.auth.status,
    }),
    ...mapGetters(["getTable"]),
    notifications() {
      return this.$store.state.notifications.notifications
        .filter((notification) => {
          return !notification.archived && notification.notifications_count > 0;
        })
        .reverse();
    },
  },
  methods: {
    ...mapActions(["createNotification", "logIn"]),
    openAuthOverlay() {
      this.overlayContent = "auth";
      this.overlay = true;
    },
    openChainOverlay() {
      let chains = this.$store.state.chain.chains;
      let maxSortOrder =
        chains.length === 0
          ? -1
          : Math.max(...chains.map((chain) => chain.sort_order));
      this.chain = { id: uuidv4(), sort_order: maxSortOrder + 1 };
      this.overlayMode = EditDialogModeEnum.Add;
      this.overlayContent = "chain";
      this.overlay = true;
    },
    openChainEditOverlay(chainId) {
      this.chain = this.$store.state.chain.chains.filter(
        (chain) => chain.chain_id == chainId
      )[0];
      this.overlayMode = EditDialogModeEnum.Edit;
      this.overlayContent = "chain";
      this.overlay = true;
    },
    openChainTableOverlay(table) {
      this.chain = this.$store.state.chain.chains.filter(
        (chain) => chain.chain_id == table.chain
      )[0];
      this.table = table;
      this.overlayContent = "chain-table";
      this.overlay = true;
    },
    openAlertEditOverlay(alert) {
      this.alertId = alert.id;
      this.alertSource = alert.source;
      this.alertMetric = alert.metric;
      this.overlayContent = "alert";
      this.overlay = true;
    },
    openMutlipleAlertsEditOverlay(alertInfo) {
      this.alertSources = alertInfo.alertSources;
      this.alertMetrics = alertInfo.alertMetrics;
      this.overlayContent = "multiple-alerts";
      this.overlay = true;
    },
    openNodeOverlay(value) {
      this.nodeOverlayChainId = value;
      let chain = this.$store.state.chain.chains.filter(
        (chain) => chain.id == value
      )[0];
      let sort_order = 0;
      if (chain) {
        let nodes = chain.nodes;
        sort_order =
          nodes.length == 0
            ? 0
            : Math.max(...nodes.map((node) => node.sort_order)) + 1;
      }
      this.node = { id: uuidv4(), sort_order };
      this.overlayMode = EditDialogModeEnum.Add;
      this.overlayContent = "node";
      this.overlay = true;
    },
    openNodeEditOverlay({ chainId, nodeId }) {
      this.chain = this.$store.state.chain.chains.filter(
        (chain) => chain.id == chainId
      )[0];
      this.node = this.chain.nodes.filter((node) => node.id == nodeId)[0];
      this.nodeOverlayChainId = this.chain.id;
      this.overlayMode = EditDialogModeEnum.Edit;
      this.overlayContent = "node";
      this.overlay = true;
    },
    openMetricsAndAlertsOverlay({ chainId, nodeId }) {
      this.chain = this.$store.state.chain.chains.filter(
        (chain) => chain.id == chainId
      )[0];
      this.node = this.chain.nodes.filter((node) => node.id == nodeId)[0];
      this.overlayMode = EditDialogModeEnum.Edit;
      this.overlayContent = "metrics-and-alerts";
      this.overlay = true;
    },
    openIngestorOverlay() {
      let ingestors = this.$store.state.ingestors.ingestors;
      let maxSortOrder =
        ingestors.length == 0
          ? -1
          : Math.max(...ingestors.map((ingestor) => ingestor.sort_order));
      this.ingestor = { id: uuidv4(), sort_order: maxSortOrder + 1 };
      this.overlayMode = EditDialogModeEnum.Add;
      this.overlayContent = "ingestor";
      this.overlay = true;
    },
    openIngestorEditOverlay(ingestorId) {
      this.ingestor = this.$store.state.ingestors.ingestors.filter(
        (i) => i.id == ingestorId
      )[0];
      this.overlayMode = EditDialogModeEnum.Edit;
      this.overlayContent = "ingestor-edit";
      this.overlay = true;
    },
    openIngestorSetAccessKeyOverlay(ingestorId) {
      this.ingestor = this.$store.state.ingestors.ingestors.filter(
        (i) => i.id == ingestorId
      )[0];
      this.overlayContent = "ingestor-set-access-key";
      this.overlay = true;
    },
    async getLoginStatus() {
      let result = await loginService.getLoginStatus();
      if (result.success) {
        this.logIn(result.accessToken);
      }
    },
  },
  async mounted() {
    this.getLoginStatus();
    this.messageManager = messageService.initizeMessageManager(this.$store);

    let loc = window.location,
      new_uri;
    if (loc.protocol === "https:") {
      new_uri = "wss:";
    } else {
      new_uri = "ws:";
    }
    new_uri += "//" + loc.host;
    new_uri += "/ws";

    let self = this;
    await OpenWebsocketConnection();
    async function OpenWebsocketConnection() {
      try {
        let web_socket_manager = new webSocketService.WebSocketManager();
        let socket = await web_socket_manager.initWebsocket(new_uri, 5000, 5);

        // If we make it here initWebSocket was always successful
        socket.send(JSON.stringify([{ message: "initial_state" }]));

        // Ping the server for testing purposes
        web_socket_manager.startInterval(() => {
          socket.send(JSON.stringify([{ message: "ping" }]));
        }, 1000);

        socket.onmessage = function (event) {
          let event_data = JSON.parse(event.data);
          self.messageManager.receiveMessage(event_data);
        };

        socket.onclose = function (event) {
          web_socket_manager.clearIntervals();
          if (event.wasClean) {
            console.log(
              `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
            );
          } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log("[close] Connection died");
          }
          console.log("Attempting reconnection");
          OpenWebsocketConnection();
        };

        socket.onerror = function (error) {
          console.log(`[error] ${error.message}`);
        };
      } catch (e) {
        Sentry.captureException(e);
        console.log("Could not connect, reconnect attempt in 5 seconds");
        sleepService.sleep(5000).then(() => OpenWebsocketConnection());
      }
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lilita+One&display=swap");
@font-face {
  font-family: etna;
  src: url("./assets/Etna-Sans-serif.otf") format("opentype");
}
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
.blur {
  filter: blur(3px);
}
</style>