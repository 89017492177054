<template>
  <v-card width="350px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: black; text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px">mdi-bell-off</v-icon>
        Silence Alerts
      </span>
    </v-card-title>
    <v-col class="pa-6">
      <v-card-text class="pa-0" color="black">
        <span style="font-family: Inter; font-weight: 600">
          Duration
        </span></v-card-text
      >
      <v-select
        v-bind:items="durations"
        v-model="duration"
        single-line
        outlined
        label="Choose a chain type"
        dense
        color="black"
      ></v-select>
      <div style="text-align: right">
        <v-btn
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn"
          v-on:click="$emit('cancel')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Cancel</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          :color="saveButtonColor"
          class="rounded-md ml-4 white--text pr-3"
          v-on:click="silenceAlerst"
          :disbled="!authStatus"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Save</span
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import axios from "axios";
import { flatten } from "../../../utils/flatten"
import { mapState } from "vuex";

export default {
  props: {
    alertSources: Array,
    alertMetrics: Array
  },
  data() {
    return {
      // Value is the number of seconds in the future, -1 = forever
      durations: [
        { value: "900", text:"15 minutes"},
        { value: "3600", text: "1 hour"},
        { value: "86400", text: "24 hours"},
        { value: "-1", text: "Forever" }],
      duration: { value: "3600", text: "1 hour"},
      is_silenced: false
    };
  },
  computed: {
    ...mapState({
      authStatus: (state) => state.auth.status,
    }),
    saveButtonColor() { return this.authStatus ? "black" : "grey"; }
  },
  mounted() {
    let chains = this.$store.state.chain.chains;
    let nodes = flatten(chains.map(chain => chain.nodes));
    let alerts = flatten(nodes.map(node => node.alerts));
    let matchingAlerts = alerts.filter(alert => alert.node_id == this.alertSource && alert.metric == this.alertMetric);
    if (matchingAlerts.length > 0) {
      this.is_silenced = matchingAlerts[0].is_silenced;
      if (this.is_silenced) {
        this.durations =       [
            { value: "0", text: "Un-silence" }
          ];
        this.duration = { value: "0", text: "Un-silence" };
      }
    }
  },
  methods: {
    async silenceAlerst() {
      // console.log("1. silenceAlerst called");
      // console.log("2. alertSources=", this.alertSources);
      // console.log("3. alertMetrics=", this.alertMetrics);
      if (!this.authStatus) {
        return;
      }
      let silenceLength = parseInt(this.duration.value);
      let silenceUntil = null;
      if (silenceLength <= 0) {
        silenceUntil = null;
      }
      else {
        silenceUntil = new Date(new Date().getTime() + silenceLength * 1000);
      }
      for (let index = 0; index < this.alertSources.length; index++) {
        let alertSource = this.alertSources[index];
        let alertMetric = this.alertMetrics[index];
        let payload = {
          node_id: alertSource,
          metric: alertMetric,
          silence: silenceLength != 0,
          silence_until: silenceUntil
        };
        await axios.post("/api/v1/silencealert/", payload);
      }
      this.$emit("confirm");

    }
  },
};
</script>

<style>
</style>