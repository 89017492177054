<template>
  <v-card outlined>
    <chain-table-banner v-bind:table="table" v-on:editChainTable="editChainTable" />

    <v-container class="pa-0" style="overflow-x: auto">
      <v-container
        v-bind:style="{
          minWidth: `${tableWidth + 'px'}`,
        }"
      >
        <chain-table-header v-bind:header="header"/>
        <chain-table-row
          v-for="node in nodes"
          v-bind:key="node.id"
          v-bind:node="node"
          v-bind:table="table"
        />
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import ChainTableBanner from "./ChainTableBanner.vue"
import ChainTableHeader from "./ChainTableHeader.vue"
import ChainTableRow from "./ChainTableRow.vue";

import { mapGetters } from "vuex";

export default {
  props: {
    chain: Object,
    table: Object,
  },
  components: {
    ChainTableBanner,
    ChainTableHeader,
    ChainTableRow,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["getTableNodes", "getTableHeader", "getTableWidth"]),
    header() {
      return this.table ? this.getTableHeader({chain: this.chain.id, module: this.table.module}) : [];
    },
    nodes() {
      return this.chain ? this.getTableNodes({chain: this.chain, module: this.table.module}) : [];
    },
    tableWidth() {
      return this.table ? this.getTableWidth({chain: this.chain.id, module: this.table.module}) : 474;
    },
  },
  methods: {
    editChainTable() {
      this.$emit("editChainTable", this.table);
    },
  },
};
</script>

<style>
</style>