import Vue from 'vue';

export default Vue.mixin({
    methods: {
        chainNotifications(chain) {
          const ids = [];
          chain.nodes.forEach((node) => {
            ids.push(node.id);
          });
          return this.$store.state.notifications.notifications.filter((notification) => {
              return (
                ((notification.scope === "chain" &&
                  notification.source === chain.id) ||
                (notification.scope === "node" &&
                  ids.includes(notification.source) &&
                  !notification.archived)) &&
                  notification.notifications_count > 0
              );
            });
        },
        activeNodes(chain) {return chain.nodes.filter((node) => this.nodeStatus(node) == "active").length;},
        errorsNodes(chain) {return chain.nodes.filter((node) => this.nodeStatus(node) == "errors").length;},
        disconnectedNodes(chain) {return chain.nodes.filter((node) => this.nodeStatus(node) == "disconnected").length;},
        notConnectedNodes(chain) {return chain.nodes.filter((node) => this.nodeStatus(node) == "not connected").length;},
        chainStatus(chain) {
          if (this.activeNodes(chain) + this.notConnectedNodes(chain) == chain.nodes.length) {
            if (this.activeNodes(chain) > 0) return "active";
            else return "not connected";
          } else if (this.disconnectedNodes(chain) > 0) return "disconnected";
          else if (this.errorsNodes(chain) > 0) return "errors";
          else return "other";
        },
        chainStatusBackgroundColor(chain) {
          if (this.chainStatus(chain) == "active") return "hsl(120, 67%, 94%)";
          else if (this.chainStatus(chain) == "errors") return "hsl(40, 87%, 94%)";
          else if (this.chainStatus(chain) == "disconnected") return "hsl(0, 87%, 94%)";
          else if (this.chainStatus(chain) == "not connected") return "hsl(0, 0%, 94%)";
          else return "hsl(0, 0%, 94%)";
        },
        chainStatusForegroundColor(chain) {
          if (this.chainStatus(chain) == "active") return "hsl(120, 67%, 39%)";
          else if (this.chainStatus(chain) == "errors") return "hsl(40, 87%, 49%)";
          else if (this.chainStatus(chain) == "disconnected") return "hsl(0, 87%, 59%)";
          else if (this.chainStatus(chain) == "not connected") return "hsl(0, 0%, 59%)";
          else return "hsl(0, 0%, 59%)";
        },
    },
});
      