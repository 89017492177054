<template>
    <v-list-item class="px-4 mb-0">
        <v-list-item-content class="py-0">
            <div style="width:100%; display:flex; justify-content:space-between; align-items:center;">
                <v-list-item-title>
                    <span style="font-family:Inter; font-weight:600;">{{ title }}: </span>
                    <span style="font-family:Inter; font-weight:500;">Alert me when {{ prefix }}</span>
                    <v-text-field class="conditionField" v-if="type == 'string'" v-bind:placeholder="defaultValue"
                        v-bind:value="conditionValue" v-on:change="updateAlertCondition" hide-details outlined dense light
                        color="black" type="text" style="display:inline-block; width:120px; margin:2px 6px!important;" />
                    <v-text-field v-else-if="hasCondition" v-bind:placeholder="defaultValue" v-bind:value="conditionValue"
                        v-on:change="updateAlertCondition" hide-details outlined dense light color="black" type="number"
                        style="display:inline-block; width:80px; margin:2px 6px!important;" />
                    <span style="font-family:Inter; font-weight:500;">{{ [units,
                        suffix].filter(str => str != "").join('') }}.</span>
                </v-list-item-title>
                <div>
                    <v-chip v-on:click="toggleAlert" light label outlined
                        v-bind:color="active ? 'green green--text' : 'grey grey--text'" class="rounded-pill px-2 py-0"
                        v-bind:style="active ? 'background-color:#eaf6ea!important; cursor:pointer;' : 'background-color:#f0f0f0!important; cursor:pointer;'">
                        {{ active ? 'Alerting' : 'Alert' }}
                    </v-chip>

                </div>
            </div>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: ['metric'],
    computed: {
        active() {
            return this.metric.configuration.alerting;
        },
        title() {
            return this.metric.information.Title;
        },
        prefix() {
            return this.metric.information.Condition_Prefix;
        },
        suffix() {
            return this.metric.information.Condition_Suffix;
        },
        hasCondition() {
            return this.metric.information.Has_Condition
        },
        type() {
            return this.metric.information.Condition_Type
        },
        units() {
            return this.metric.information.Condition_Units
        },
        defaultValue() {
            return this.metric.information.Condition_Default
        },
        conditionValue() {
            return this.metric.configuration.condition
        },
        description() {
            return this.metric.information.Description;
        }
    },
    methods: {
        toggleAlert() {
            const metric = {
                Module: this.metric.information.Module,
                Name: this.metric.information.Name
            }
            if (this.active) {
                this.$emit('update', metric, {
                    alerting: false,
                    condition: this.conditionValue,
                    monitoring: true
                });
            } else if (this.conditionValue != "") {
                this.$emit('update', metric, {
                    alerting: true,
                    condition: this.conditionValue,
                    monitoring: true
                });
            } else {
                this.$emit('update', metric, {
                    alerting: true,
                    condition: this.defaultValue,
                    monitoring: true
                });
            }
        },
        updateAlertCondition(value) {
            const metric = {
                Module: this.metric.information.Module,
                Name: this.metric.information.Name
            }
            this.$emit('update', metric, {
                alerting: this.active,
                condition: value,
                monitoring: true
            });
        }
    },
}
</script>