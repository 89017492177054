<template>
  <v-row>
    <v-col cols="auto" class="py-1">
      <span style="font-family: Inter; font-size: 0.875rem; font-weight: 600; margin-right: 4px;">
      {{ alert.url }}</span>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto" class="py-1"><span style="font-family: Inter; font-size: 0.875rem; font-weight: 400">{{ connectsValue
    }}</span></v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    alert: Object,
    node: Object,
  },
  computed: {
    connectsValue() {
      let value = this.$store.state.metrics.metric[this.alert.id]
      if (value == "1") {
        return "Available"
      } else {
        return "Unavailable"
      }
    },
  },
};
</script>
