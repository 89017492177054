<template>
  <v-container class="pa-6" style="max-width: 1680px;">
    <v-row align="center">
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span style="font-family: Inter; text-transform: uppercase">
            Alerts
          </span>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-chip v-if="errorNotifications > 0 && show.errors" v-on:click="show.errors = !show.errors" light label outlined class="rounded-pill"
          style="height: 36px; background-color: hsl(0, 87%, 94%) !important; color: hsl(0, 87%, 59%) !important; border-color: hsl(0, 87%, 59%) !important;">
          <v-icon color="hsl(0, 87%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 87%, 59%)">{{ errorNotifications }}
            <span v-if="errorNotifications == 1">Error</span><span v-else>Errors</span>
          </span>
        </v-chip>
        <v-chip v-else v-on:click="show.errors = !show.errors" light label outlined class="rounded-pill"
          style="height: 36px; background-color: hsl(0, 0%, 94%) !important; color: hsl(0, 0%, 59%) !important; border-color: hsl(0, 0%, 59%) !important">
          <v-icon color="hsl(0, 0%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 0%, 59%)">{{ errorNotifications }}
            <span v-if="errorNotifications == 1">Error</span><span v-else>Errors</span>
          </span>
        </v-chip>
        <v-chip v-if="warningNotifications > 0 && show.warnings" v-on:click="show.warnings = !show.warnings" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(40, 87%, 94%) !important; color: hsl(40, 87%, 49%) !important; border-color: hsl(40, 87%, 49%) !important;">
          <v-icon color="hsl(40, 87%, 49%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(40, 87%, 49%)">{{ warningNotifications }}
            <span v-if="warningNotifications == 1">Warning</span><span v-else>Warnings</span>
          </span>
        </v-chip>
        <v-chip v-else v-on:click="show.warnings = !show.warnings" light label outlined class="rounded-pill ml-3"
        style="height: 36px; background-color: hsl(0, 0%, 94%) !important; color: hsl(0, 0%, 59%) !important; border-color: hsl(0, 0%, 59%) !important">
          <v-icon color="hsl(0, 0%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 0%, 59%)">{{ warningNotifications }}
            <span v-if="warningNotifications == 1">Warning</span><span v-else>Warnings</span>
          </span>
        </v-chip>
        <v-chip v-if="informationNotifications > 0 && show.notifications" v-on:click="show.notifications = !show.notifications" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(220, 87%, 94%) !important; color: hsl(220, 87%, 49%) !important; border-color: hsl(220, 87%, 49%) !important;"><v-icon color="hsl(220, 87%, 49%)" class="mr-1">mdi-circle</v-icon><span
            style="font-family: Inter; color: hsl(220, 87%, 49%)">{{ informationNotifications }}
            <span v-if="informationNotifications == 1">Notification</span><span v-else>Notifications</span></span></v-chip>
        <v-chip v-else v-on:click="show.notifications = !show.notifications" light label outlined class="rounded-pill ml-3"
        style="height: 36px; background-color: hsl(0, 0%, 94%) !important; color: hsl(0, 0%, 59%) !important; border-color: hsl(0, 0%, 59%) !important"><v-icon color="hsl(0, 0%, 59%)" class="mr-1">mdi-circle</v-icon><span
            style="font-family: Inter; color: hsl(0, 0%, 59%)">{{ informationNotifications }}
            <span v-if="informationNotifications == 1">Notification</span><span v-else>Notifications</span></span></v-chip>
      </v-col>
      <v-col cols="auto" class="pl-0">
        <v-btn
          v-on:click="silenceAll"
          icon
          outlined
          color="hsl(0, 0%, 59%)"
          style="background-color: hsl(0, 0%, 94%)"
        >
          <v-icon small>mdi-bell-off</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" v-if="activeNotifications > 0">
      <v-col cols="auto" class="pr-0">
        <div class="text-h6">
          <span style="font-family: Inter;">
            Priority Alerts
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="notification in pinnedNotifications" v-bind:key="notification.id" cols="12" sm="12" md="6" xl="4">
        <notification v-bind:notification="notification" v-on:editAlert="editAlert(notification)" v-bind:show="show"/>
      </v-col>
    </v-row>
    <v-row align="center" v-if="notifications.length > 0">
      <v-col cols="auto" class="pr-0">
        <div class="text-h6">
          <span style="font-family: Inter;">
            Historical Alerts
          </span>
        </div>
      </v-col>
    </v-row>
    <v-timeline class="mt-6" style="padding-top: 0px; padding-bottom: 0px">
      <timeline-item v-for="notification in historicalNotifications" v-bind:key="notification.id" v-bind:notification="notification" display="alerts" />
    </v-timeline>
  </v-container>
</template>

<script>
import Notification from "../components/Notifications/Notification.vue"
import TimelineItem from "../components/Notifications/TimelineItem.vue"

import { mapGetters } from "vuex";


export default {
  components: {
    Notification,
    TimelineItem
  },
  data: () => ({
    show: {
      errors: true,
      warnings: true,
      notifications: true,
      archived: true
    }
  }),
  computed: {
    ...mapGetters({
      allNotifications: "allNotificationsByTimeCleared",
      allNotificationsByTimeRaised: "allNotificationsByTimeRaised"
    }),
    notifications() {
      let toggles = this.show;
      return this.allNotifications.filter((notification) => {
        return !(!toggles.errors && notification.type=='error') &&
        !(!toggles.warnings && notification.type=='warning') && 
        !(!toggles.notifications && notification.type=='information') &&
        !(!toggles.archived && notification.archived);
      });
    },
    pinnedNotifications() {
      let toggles = this.show;
      return this.allNotificationsByTimeRaised.filter((notification) => {
        return !(!toggles.errors && notification.type=='error') &&
        !(!toggles.warnings && notification.type=='warning') && 
        !(!toggles.notifications && notification.type=='information') &&
        !notification.archived
      });
    },
    historicalNotifications() {
      return this.notifications.filter((notification) => {
        return notification.archived;
      })
    },
    activeNotifications() {
      return this.notifications.filter((notification) => {
        return !notification.archived;
      }).length;
    },
    successNotifications() {
      return this.allNotifications.filter((notification) => {
        return notification.type == "success" && !notification.archived;
      }).length;
    },
    informationNotifications() {
      return this.allNotifications.filter((notification) => {
        return notification.type == "information" && !notification.archived;
      }).length;
    },
    warningNotifications() {
      return this.allNotifications.filter((notification) => {
        return notification.type == "warning" && !notification.archived;
      }).length;
    },
    errorNotifications() {
      return this.allNotifications.filter((notification) => {
        return notification.type == "error" && !notification.archived;
      }).length;
    },
  },
  methods: {
    accentColor(notification) {
      if (notification.archived) return "hsl(0, 0%, 59%)";
      switch (notification.type) {
        case "success":
          return "hsl(120, 67%, 39%)";
        case "information":
          return "hsl(220, 87%, 49%)";
        case "warning":
          return "hsl(40, 87%, 49%)";
        case "error":
          return "hsl(0, 87%, 59%)";
        default:
          return "hsl(0, 0%, 59%)";
      }
    },
    icon(notification) {
      switch (notification.type) {
        case "success":
          return "mdi-check-circle-outline";
        case "information":
          return "mdi-information-slab-circle-outline";
        case "warning":
          return "mdi-alert-outline"
        case "error":
          return "mdi-alert-circle-outline";
        default:
          return "mdi-bell-outline"
      }
    },
    notificationTypeColor(notificationType, notificationArchived) {
      if (notificationArchived) return "grey";
      else if (notificationType == "success") return "green";
      else if (notificationType == "error") return "red";
      else if (notificationType == "warning") return "#f4b236";
      else return "grey";
    },
    silenceAll() {
      // Ingestor_Not_Connect not currently silencable
      var notifications = this.notifications;
      var alertSources = notifications.map(n => n.source);
      var alertMetrics = notifications.map(n => n.metric);
      this.$emit('editMultipleAlerts', { alertSources, alertMetrics })
    },
    editAlert(notification) {
      this.$emit('editAlert', {
        metric: notification.metric,
        source: notification.source
      })
    }
  },
};
</script>

<style>
.v-timeline-item:last-of-type {
  padding-bottom: 0px;
}
</style>