<template>
  <v-card width="400px" light class="rounded-md">
    <v-form ref="editForm" v-model="valid" @submit="callAddChain" @submit.prevent>
      <v-card-title
        primary-title
        style="background-color: black; text-align: left"
        class="pa-6"
        color="white"
      >
        <span style="font-family: Inter; color: white" class="text-h5">
          <v-icon v-if="isAddMode" color="white" style="margin-top: -5px"
            >mdi-plus</v-icon
          >
          <v-icon v-else color="white" style="margin-top: -3px">mdi-cog</v-icon>
          {{ mode }} Chain
        </span>
      </v-card-title>
      <v-col class="pa-6">
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Chain Name
          </span></v-card-text
        >
        <v-text-field
          v-model="name"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="Chain 1"
          :rules="[rules.notEmpty]"
        >
        </v-text-field>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Chain Type
          </span></v-card-text
        >
        <v-select
          v-bind:items="chainTypes"
          v-model="type"
          single-line
          outlined
          label="Choose a chain type"
          dense
          color="black"
          @change="changeType"
        ></v-select>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Icon
          </span></v-card-text
        >
        <v-img
          min-height="24"
          max-width="24"
          contain
          v-bind:src="this.icon"
          class="mb-6"
          style="margin-bottom: 2px; margin-top: -2px"
        ></v-img>

        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            BP Name
          </span></v-card-text
        >
        <v-text-field
          v-model="bp_name"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="BP Name"
        >
        </v-text-field>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Vote Decay Type
          </span></v-card-text
        >
        <v-select
          v-bind:items="voteDecayTypes"
          v-model="voteDecayType"
          single-line
          outlined
          label="Choose a vote decay type"
          dense
          color="black"
          :readonly="isReadOnly"
        ></v-select>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Chain Id
          </span></v-card-text
        >
        <v-text-field
          v-model="chain_id"
          outlined
          dense
          light
          :readonly="isReadOnly"
          color="black"
          class="mb-6"
          type="text"
          placeholder="Chain Id"
          :rules="[rules.notEmpty]"
        ></v-text-field>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Sort Order
          </span></v-card-text
        >
        <v-text-field
          v-model="sort_order"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="Sort Order"
          :rules="[rules.nonNegativeInteger]"
        ></v-text-field>
        <div style="text-align: right">
          <v-btn
            large
            depressed
            text
            class="ml-4 rounded-md no-active-btn"
            v-on:click="$emit('cancel')"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >Cancel</span
            >
          </v-btn>
          <v-btn
            v-if="isEditMode"
            depressed
            large
            color="red"
            class="rounded-md ml-4 white--text pr-3"
            v-on:click="callDeleteChain"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >Delete</span
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            depressed
            large
            color="black"
            class="rounded-md ml-4 white--text pr-3"
            type="submit"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >{{ okButtonCaption }}</span
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import chainTypeService from "../../../services/chainTypeService";
import { EditDialogModeEnum } from "../../../enums/editDialogModeEnum";
import errorUtility from "../../../services/errorUtility";

import { mapActions } from "vuex";

export default {
  props: {
    chain: Object,
    mode: String,
  },
  data() {
    return {
      voteDecayTypes: [
        { value: 0, text: "None"},
        { value: 1, text: "13 weeks"},
        { value: 2, text: "52 weeks"},
      ],
      voteDecayType: 0,
      id: "",
      name: "",
      type: "",
      icon: "",
      chain_id: "",
      bp_name: "",
      isReadOnly: true,
      sort_order: 0,
      rules: {
        nonNegativeInteger: v => {
          const pattern = /^\d*$/
          let result = pattern.test(v);
          return result || 'Must be a non-negative integer.'
        },
        notEmpty: v => {
          return !!v || 'Must not be empty'
        }
      },
      valid: true,
    };
  },
  computed: {
    okButtonCaption() {
      return this.mode == EditDialogModeEnum.Add ? "Create" : "Save";
    },
    isAddMode() {
      return this.mode == EditDialogModeEnum.Add;
    },
    isEditMode() {
      return this.mode == EditDialogModeEnum.Edit;
    },
    chainTypes() {
      return chainTypeService.getAllChainsMetaData().map(c => c.name);
    }
  },
  mounted() {
    this.id = this.chain.id;
    this.name = this.chain.name;
    this.icon = this.chain.icon;
    this.bp_name = this.chain.bp_name;
    this.chain_id = this.chain.chain_id;
    this.sort_order = this.chain.sort_order;
    this.voteDecayType = this.chain.vote_decay_type;

    let chainType = chainTypeService.getChainMetaDataByChainId(this.chain_id);
    if (this.isAddMode) {
      // If we are adding a new chain type we need to set up the new meta data
      this.icon = chainType.icon;
      this.chain_id = chainType.chain_id;
      this.isReadOnly = chainType.isReadOnly;
      this.voteDecayType = chainType.vote_decay_type;
    }
    else
    {
      if (!chainType) {
        // No match so this is a private chain
        this.type = "Private Chain";
        this.isReadOnly = false;
      } else {
        this.type = chainType.name;
        this.isReadOnly = chainType.isReadOnly;
      }
    }
  },
  methods: {
    ...mapActions(["initialiseTables"]),
    async callAddChain() {
      if (!this.$refs.editForm.validate()) { return; }
      let payload = {
        id: this.id,
        name: this.name,
        chain_id: this.chain_id,
        icon: this.icon,
        bp_name: this.bp_name ?? "",
        vote_decay_type: this.voteDecayType,
        active: true,
        sort_order: this.sort_order,
      };
      await axios.post("/api/v1/chain/", payload);
      this.$emit("confirm");
    },
    changeType(chainType) {
      chainType = chainTypeService.getChainMetaDataByName(chainType);
      this.icon = chainType.icon;
      this.chain_id = chainType.chain_id;
      this.isReadOnly = chainType.isReadOnly;
      this.voteDecayType = chainType.vote_decay_type;
    },
    async callDeleteChain() {
      if (confirm("Are you sure you want to delete this chain?") == false) {
        return;
      }
      try {
        await axios.delete(`/api/v1/chain/${this.id}`);
        this.initialiseTables();
        this.$router.push('/');
        this.$emit("confirm");
      } catch (e) {
        errorUtility.handleServerError(e);
      }
    }
  },
};
</script>

<style>
</style>