<template>
  <v-container
      v-bind:style="{
        backgroundColor: colour,
      }"
    >
    <v-row>
      <v-col cols="auto" class="pa-0" align-self="center">
        <v-card-title
          primary-title
          style="text-align: left"
          class="pa-6"
          color="white"
        >
          <span
            style="
              font-family: Inter;
              color: white;
              font-size: 18px;
              line-height: 18px;
            "
          >
            <v-icon small color="white" style="margin-top: -3px"
              >mdi-cube-outline</v-icon
            >
            {{ title }}
          </span>
        </v-card-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        v-if="authStatus"
        cols="auto"
        class="px-6 py-3"
        align-self="center"
      >
        <v-btn v-on:click="editChainTable" text icon color="white">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    table: Object,
  },
  computed: {
    ...mapGetters(["getTableTitle", "getTableColour"]),
    authStatus() {
      return this.$store.state.auth.status;
    },
    colour() {
      return this.table ? this.getTableColour(this.table.module) : "hsl(0, 0%, 94%)";
    },
    title() {
      return this.table ? this.getTableTitle(this.table.module) : "Nodes";
    },
  },
  methods: {
    editChainTable() {
      this.$emit("editChainTable");
    },
  },
}
</script>