<template>
  <v-container class="pa-6">
    <v-row align="center">
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span
            style="
              font-family: Inter;
              text-transform: uppercase;
              font-weight: 600;
            "
          >
            Setup
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <IngestorsTable 
          v-on:addNewIngestor="$emit('addNewIngestor')"
          v-on:editIngestor="callEditIngestor"
          v-on:setIngestorAccessKey="callSetIngestorAccessKey"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IngestorsTable from "../components/Setup/IngestorsTable.vue";

export default {
  components: {
    IngestorsTable
  },
  data: () => ({
    overlayMode: null,
    overlay: false,
    overlayMetricsAndAlerts: false,
  }),
  computed: {
    authStatus() {
      return this.$store.state.auth.status;
    },
  },
  methods: {
    callEditIngestor(id) {
      this.$emit("editIngestor", id);
    },
    callSetIngestorAccessKey(id) {
      this.$emit("setIngestorAccessKey", id);
    },
  },
};
</script>

<style>
</style>