<template>
    <v-chip
        v-if="headBlock"
        color="black"
        outlined
        label
        style="
            text-transform: capitalize;
            height: 36px;
            background-color: white !important;
        "
    >
        Head Block: {{ headBlock }}
    </v-chip>
</template>

<script>
import { formatMetricValue } from "../../services/metricService";

export default {
    props: {
        blockProducer: Object,
    },
    computed: {
        headBlock() {
            let alert = this.blockProducer
                .alerts
                .filter(alert => alert.metric == `Node/Head_Block`)[0];

            if (!alert) {
                return "N/A";
            }

            let value = this.$store.state.metrics.metric[alert.id];
            if (!value) {
                return "N/A";
            }
            return formatMetricValue("Node", "Head_Block", value);
        },
    }
}
</script>
