<template>
  <v-container class="pa-6">
    <v-row align="center">
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span style="font-family: Inter; text-transform: uppercase">Ingestor Name</span>
        </div>
      </v-col>
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span style="font-family: Inter;">{{ ingestor.name }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span style="font-family: Inter; text-transform: uppercase">Ingestor Sort Order</span>
        </div>
      </v-col>
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span style="font-family: Inter;">{{ ingestor.sort_order }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="authStatus" align="center">
        <v-col cols="auto" class="pl-0">
            <v-btn
                large
                depressed
                text
                class="ml-4 rounded-md "
                @click="openIngestorOverlay"
            >
                <span
                style="
                    font-family: Inter;
                    text-transform: none;
                    font-weight: 600;
                    margin-top: -3px;
                "
                >Edit</span
                >
            </v-btn>
        </v-col>
        <v-col cols="auto" class="pl-0">
            <v-btn
                large
                depressed
                text
                class="ml-4 rounded-md "
                @click="openSetIngestorAccessKeyOverlay"
            >
                <span
                style="
                    font-family: Inter;
                    text-transform: none;
                    font-weight: 600;
                    margin-top: -3px;
                "
                >Set Access Key</span
                >
            </v-btn>
        </v-col>
    </v-row>
    <v-overlay v-bind:value="overlayEditIngestor" opacity="0.75">
      <ingestor-overlay
        :mode=overlayMode
        :ingestor=ingestor
        :key=ingestor.id
        v-on:close="overlayEditIngestor = false"
      />
    </v-overlay>
    <v-overlay v-bind:value="overlayAccessKey" opacity="0.75">
      <ingestor-access-key-overlay
        :ingestor=ingestor
        :key=ingestor.id
        v-on:close="overlayAccessKey = false"
      />
    </v-overlay>
  </v-container>
</template>

<script>
import { EditDialogModeEnum } from '../enums/editDialogModeEnum';
import IngestorOverlay from "../components/App/IngestorOverlay.vue";
import IngestorAccessKeyOverlay from "../components/App/IngestorAccessKeyOverlay.vue";

export default {
  components: {
      IngestorOverlay,
      IngestorAccessKeyOverlay
  },
  data() {
    return {
      overlayMode: null,
      overlayEditIngestor: false,
      overlayAccessKey: false,
    }
  },
  computed: {
    ingestorId() { return this.getIngestorId(); },
    ingestor() { return this.getIngestor(); },
    authStatus() { return this.$store.state.auth.status; },
  },
  methods: {
    getIngestor() {
      let ingestorId = this.getIngestorId();
      return this.$store.state.ingestors.ingestors.filter(i => i.id == ingestorId)[0];
    },
    getIngestorId() {
      return this.$route.params.ingestor;
    },
    openIngestorOverlay() {
      this.overlayMode = EditDialogModeEnum.Edit;
      this.overlayEditIngestor = true;
    },
    openSetIngestorAccessKeyOverlay() {
      this.overlayAccessKey = true;
    }
  },
}
</script>
<style>
</style>
