import * as Sentry from "@sentry/browser";

function getErrorMessages(e) {
    var messages = [];

    // eslint-disable-next-line no-prototype-builtins
    if (e.hasOwnProperty('response') && e.response.hasOwnProperty('data')) {
        var data = e.response.data;

        if (typeof data === "string") {
            messages.push(removeStackTrace(data));
        }
        if (data.title) {
            messages.push(removeStackTrace(data.title));
        }
        if (data.message) {
            messages.push(removeStackTrace(data.message));
        }
        if (data.errors) {
            for (var key in data.errors) {
                var errorsForKey = data.errors[key];

                for (var i = 0; i < errorsForKey.length; i++) {
                    messages.push(removeStackTrace(errorsForKey[i]));
                }
            }
        }
    }
    else if (typeof e === "string") {
        messages.push(removeStackTrace(e));
    }
    else {
        messages.push(removeStackTrace(JSON.stringify(e)));
    }

    return messages;
}

function getErrorMessage(e) {
    if (typeof e === "string") {
        return removeStackTrace(e);
    }
    var errorMessage = getErrorMessages(e);
    if (errorMessage == "{}" || errorMessage == null || errorMessage == "") {
        if (e == "Error: Request failed with status code 403") {
            return e + ". You do not have the required role to perform this action. "
        } else {
            return removeStackTrace(e);
        }
    }
    return getErrorMessages(e).join('\n');
}

function removeStackTrace(e) {
    // eslint-disable-next-line no-prototype-builtins
    if (e.hasOwnProperty('indexOf'))  {

        if (e.indexOf(' at ') === -1) {
            return e;
        }
        return e.substring(0, e.indexOf(' at '));
    }
    else {
        return e;
    }
}

function handleServerError(e) {
    console.log("Error e=", e);
    let serverError = getErrorMessage(e);
    Sentry.captureException(e);
    alert(serverError);
}

function reportMessageToSentry(message) {
    Sentry.captureMessage(message);
}

function reportExceptionToSentry(err) {
    Sentry.captureException(err);
}

export default {
    getErrorMessage,
    getErrorMessages,
    handleServerError,
    reportMessageToSentry,
    reportExceptionToSentry
};
