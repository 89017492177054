<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <login
          v-if="!authStatus"
          v-on:confirm="$emit('close')"
          v-on:cancel="$emit('close')"
        />
        <logout
          v-if="authStatus"
          v-on:confirm="$emit('close')"
          v-on:cancel="$emit('close')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from "./Overlay/Login.vue";
import Logout from "./Overlay/Logout.vue";

import { mapState } from "vuex";

export default {
  components: {
    Login,
    Logout,
  },
  computed: {
    ...mapState({
      authStatus: (state) => state.auth.status,
    }),
  },
};
</script>

<style>
</style>