<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <get-ingestor-access-key v-if="!keyGenerated" :ingestor=ingestor v-on:cancel="$emit('close')" v-on:next="generateKey"/>
        <set-ingestor-access-key v-else v-bind:access_key="access_key" :ingestor=ingestor v-on:done="$emit('close')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import nacl from "tweetnacl";
import hexService from "../../services/hexService";

import GetIngestorAccessKey from './Overlay/GetIngestorAccessKey.vue';
import SetIngestorAccessKey from './Overlay/SetIngestorAccessKey.vue';

export default {
  props: {
    ingestor: Object,
  },
  components: {
    GetIngestorAccessKey,
    SetIngestorAccessKey,
  },
  data() {
    return {
      keyGenerated: false,
      id: "",
      name: "",
      access_key: "",
      binary_access_key: null,
    }
  },
  mounted() {
    this.id = this.ingestor.id;
    this.name = this.ingestor.name;
  },
  methods: {
    async generateKey() {
      this.binary_access_key = nacl.randomBytes(32);
      this.access_key = hexService.toHexString(this.binary_access_key);
      await this.callSetIngestorAccessKey();
      this.keyGenerated = true;
    },
    async callSetIngestorAccessKey() {
      let hashed_access_key = hexService.toHexString(
        nacl.hash(this.binary_access_key)
      );
      let payload = {
        id: this.id,
        access_key: hashed_access_key,
      };
      await axios.post("/api/v1/ingestor/access_key", payload);
    },
  },
};
</script>

<style>
</style>