import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from './modules/auth';
import chain from './modules/chain';
import notifications from './modules/notifications';
import messages from './modules/messages';
import ingestors from './modules/ingestors';
import metrics from './modules/metrics';
import tables from './modules/tables.js';
import version from './modules/version.js';

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		auth,
		chain,
		notifications,
		messages,
		ingestors,
		metrics,
		tables,
		version,
	},
});
