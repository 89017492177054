export default {
    state: {
        version: "",
        shouldUpgrade: false
    },
    actions: {
        setVersion(context, versionResult) {
            context.commit('setVersion', versionResult)
        },
        ignoreUpgrade(context) {
            context.commit('ignoreUpgrade')
        }
    },
    mutations: {
        setVersion(state, versionResult) {
            if (state.version != versionResult.version) {
                state.shouldUpgrade = versionResult.version != process.env.VUE_APP_HEADSUP_RELEASE;
            }
            state.version = versionResult.version;
        },
        ignoreUpgrade(state) {
            state.shouldUpgrade = false;
        }
    }
}
