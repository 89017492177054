<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <chain :mode=mode :chain=chain v-on:confirm="$emit('close')" v-on:cancel="$emit('close')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chain from "./Overlay/Chain.vue";

export default {
  props: {
    mode: String,
    chain: Object,
  },
  components: {
    Chain,
  },
  computed: {},
};
</script>

<style>
</style>