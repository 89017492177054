<template>
  <v-card width="400px" light class="rounded-md">
    <v-form ref="editForm" v-model="valid" @submit="callAddIngestor" @submit.prevent>
      <v-card-title
        primary-title
        style="background-color: black; text-align: left"
        class="pa-6"
        color="white"
      >
        <span style="font-family: Inter; color: white" class="text-h5">
          <v-icon color="white" style="margin-top: -5px">mdi-pencil</v-icon>
          {{ mode }} Ingestor
        </span>
      </v-card-title>
      <v-col class="pa-6">
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Ingestor Name
          </span></v-card-text
        >
        <v-text-field
          v-model="name"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="Ingestor 1"
          :rules="[rules.notEmpty]"
        >
        </v-text-field>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Ingestor Sort Order
          </span></v-card-text
        >
        <v-text-field
          v-model="sort_order"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="0"
          :rules="[rules.nonNegativeInteger]"
        >
        </v-text-field>
        <div style="text-align: right">
          <v-btn
            large
            depressed
            text
            class="ml-4 rounded-md no-active-btn"
            v-on:click="$emit('cancel')"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >Cancel</span
            >
          </v-btn>
          <v-btn
            depressed
            large
            color="red"
            class="rounded-md ml-4 white--text pr-3"
            v-on:click="callDeleteIngestor"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >Delete</span
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            depressed
            large
            color="black"
            class="rounded-md ml-4 white--text pr-3"
            type="submit"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >{{ okButtonCaption }}</span
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import { EditDialogModeEnum } from "../../../enums/editDialogModeEnum";
import axios from "axios";
import errorUtility from "../../../services/errorUtility";
import { nonNegativeInteger, notEmpty } from "../../../services/rulesService";

export default {
  props: {
    ingestor: Object,
    mode: String,
  },
  data() {
    return {
      id: "",
      name: "",
      sort_order: 0,
      is_silenced: false,
      rules: { nonNegativeInteger, notEmpty },
      valid: true,
    };
  },
  computed: {
    okButtonCaption() {
      return this.mode == EditDialogModeEnum.Add ? "Create" : "Save";
    },
  },
  mounted() {
    this.id = this.ingestor.id;
    this.name = this.ingestor.name;
    this.sort_order = this.ingestor.sort_order;
    this.is_silenced = this.ingestor.is_silenced;
  },
  methods: {
    async callAddIngestor() {
      if (!this.$refs.editForm.validate()) { return; }
      try {
        let payload = {
          id: this.id,
          name: this.name,
          sort_order: this.sort_order,
          is_silenced: this.is_silenced
        };
      await axios.post("/api/v1/ingestor/", payload);
      this.$emit("confirm");
      } catch (e) {
        errorUtility.handleServerError(e);
      }
    },
    async callDeleteIngestor() {
      if (confirm("Are you sure you want to delete this ingestor?") == false) {
        return;
      }
      try {
        await axios.delete(`/api/v1/ingestor/${this.id}`);
        this.$emit("confirm");
      } catch (e) {
        errorUtility.handleServerError(e);
      }
    }
  },
};
</script>

<style>
</style>