import Vue from 'vue';
import axios from "axios";

export default Vue.mixin({
    methods: {
        getAllModules(node, nodeType) {
            switch (nodeType) {
                case 0: //Block producer
                case 1: // Nodeos follower
                    return {
                        Node: {
                            id: 0,
                            name: "Node",
                            description: "Node",
                            metricsOpen: false,
                            alertsOpen: true,
                            metrics: this.getAllMetrics(node, nodeType, "Node"),
                        },
                        Vote: {
                            id: 2,
                            name: "Vote",
                            description: "Vote",
                            metricsOpen: false,
                            alertsOpen: true,
                            metrics: this.getAllMetrics(node, nodeType, "Vote"),
                        }
                    };
                case 2: // Hyperion
                    return {
                        Hyperion: {
                            id: 3,
                            name: "Hyperion",
                            description: "Hyperion",
                            metricsOpen: false,
                            alertsOpen: true,
                            metrics: this.getAllMetrics(node, nodeType, "Hyperion"),
                        },
                        History_Api: {
                            id: 4,
                            name: "History_Api",
                            description: "History API",
                            metricsOpen: false,
                            alertsOpen: true,
                            metrics: this.getAllMetrics(node, nodeType, "History_Api"),
                        }
                    };
                case 3: // Atomic Assets
                    return {
                        AtomicAssets: {
                            id: 5,
                            name: "AtomicAssets",
                            description: "Atomic",
                            metricsOpen: false,
                            alertsOpen: true,
                            metrics: this.getAllMetrics(node, nodeType, "AtomicAssets"),
                        }
                    };
                case 4: // Website
                    return {
                        Website: {
                            id: 6,
                            name: "Website",
                            description: "Website",
                            metricsOpen: false,
                            alertsOpen: true,
                            metrics: this.getAllMetrics(node, nodeType, "Website"),
                        }
                    };
                default:
                    return {};
            }
        },
        getAllMetrics(node, nodeType, module) {
            let metrics = {}
            let moduleMetrics = this.$store.state.metrics.metrics2
                .filter((metric) => {
                    return metric.Module == module;
                })
            moduleMetrics.forEach(metric => {
                if (metric.Monitoring_Available.includes(nodeType))
                {
                    metrics[metric.Name] = {
                        information: metric,
                        configuration: this.getMetricConfiguration(node, module, metric)
                    }
                }
            })
            return metrics
        },
        getMetricConfiguration(node, module, metric) {
            let metricName = `${module}/${metric.Name}`;
            if (node) {
                let alerts = node.alerts.filter(alert => {
                    return alert.metric == metricName;
                })
                let alert = null
                if (alerts.length > 0 && metric.Can_Manually_Enable) {
                    alert = alerts[0];
                } else if (!metric.Can_Manually_Enable) {
                    alerts = alerts.filter(alert => {
                        return metric.Url_Paths.includes(alert.url);
                    })
                    if (alerts.length > 0) alert = alerts[0];
                }
                if (alert) {
                    return {
                        monitoring: true,
                        alerting: alert.condition !== "",
                        condition: alert.condition || ""
                    }
                }
            }
            return {
                monitoring: false,
                alerting: false,
                condition: ""
            };
        },
        getDefaultSettings(nodeType) {
            let metrics = this.getAllModules(null, nodeType);
            Object.keys(metrics).forEach(moduleKey => {
                Object.keys(metrics[moduleKey].metrics).forEach(metricKey => {
                    let metric = metrics[moduleKey].metrics[metricKey]
                    let information = metric.information
                    metrics[moduleKey].metrics[metricKey].configuration = {
                        monitoring: information.Monitoring_Default.includes(nodeType),
                        alerting: information.Alerting_Default.includes(nodeType),
                        condition: information.Alerting_Default.includes(nodeType) && information.Has_Condition ? information.Condition_Default : ""
                    };
                })
            })
            return metrics;
        },
        async saveMetricsConfiguration(node, metrics, connectsAlerts) {
            let alerts = Object.keys(metrics).map(moduleKey => Object.keys(metrics[moduleKey].metrics)
                .filter(metricKey => metrics[moduleKey].metrics[metricKey].configuration.monitoring && metrics[moduleKey].metrics[metricKey].information.Can_Manually_Enable)
                .map(metricKey => {
                let metric = metrics[moduleKey].metrics[metricKey]
                let metricName = `${metric.information.Module}/${metric.information.Name}`
                if (metric.configuration.alerting) {
                    let condition = metric.configuration.condition
                    if (!metric.information.Has_Condition) condition = "on"
                        return {
                            name: metricName,
                            node_id: node.id,
                            metric: metricName,
                            condition: condition,
                            url: ""
                        }
                } else if (metric.configuration.monitoring) {
                    return {
                        name: metricName,
                        node_id: node.id,
                        metric: metricName,
                        condition: "",
                        url: ""
                    }
                }
                }))
                .flat()
            
            let connect_latency_alerts = connectsAlerts
                .map(ca => ({
                id: ca.alert.id,
                condition: ca.alert.condition
                }));
            
            if (node.alerts.length) {
                Object.keys(metrics).forEach(moduleKey => {
                    let module = metrics[moduleKey];
                    Object.keys(module.metrics).forEach(metricKey => {
                        let metric = module.metrics[metricKey]
                        if (!metric.information.Can_Manually_Enable) {
                            let alert_id = "";
                            alert_id = node.alerts.find(alert => {
                                return alert.metric == `${moduleKey}/${metricKey}` &&
                                metric.information.Url_Paths.includes(alert.url)
                            })?.id
                            if (metric.configuration.alerting) {
                                let condition = metric.configuration.condition
                                if (!metric.information.Has_Condition) condition = "on"
                                    connect_latency_alerts.push({
                                        id: alert_id,
                                        condition: condition
                                    });
                            } else if (metric.configuration.monitoring) {
                                connect_latency_alerts.push({
                                    id: alert_id,
                                    condition: ""
                                });
                            }
                        }
                    })
                })
            }

            let alert_bulk_update = {
                node_id: node.id,
                alerts: alerts,
                connect_latency_alerts
            };

            await axios.post("/api/v1/nodealerts/", alert_bulk_update);
        }
    }
});