import Vue from 'vue';

export default {
	state: {
		notifications: [
		],
	},
  getters: {
    getNotifications: (state) => (scope, source) => {
      return state.notifications.filter(
				(notification) => {
          return notification.scope == scope && notification.source == source && !notification.archived && notification.notifications_count > 0;
        }
			).reverse();
    },
    allNotificationsByTimeRaised (state) {
      // Sort in reverse time order
      return state.notifications.filter(notification => notification.notifications_count > 0).sort((a, b) => (a.time < b.time) ? 1: -1)
    },
    allNotificationsByTimeCleared (state) {
      return state.notifications
        .filter(notification => notification.notifications_count > 0)
        .sort((a, b) => {
          if (a.cleared == null && b.cleared == null) {
            return (a.time < b.time) ? 1 : -1;
          }
          if (a.cleared == null) return -1;
          if (b.cleared == null) return 1;
          return (a.cleared < b.cleared) ? 1 : -1;
        });
    }
  },
	mutations: {},
	actions: {
		createNotification({state, rootGetters}, event_data) {
      console.log(`creating notificaton with event: ${event_data}`)
      const {
        id,
        alert_id,
        message: text,
        source,
        metric,
        url,
        criteria,
        raised_at,
        cleared_at,
        updated_at,
        error_value,
        latest_value,
        notifications: notifications_count } = event_data;
      let scope = null;
      let type = null;

      const duplicates = state.notifications.filter(notification => {
        return notification.source == source && notification.text == text && !notification.archived;
      }).length;
      if (duplicates > 0) return;

      const chainIds = rootGetters.allChainIds;
      const nodeIds = rootGetters.allNodeIds;
      const ingestorIds = rootGetters.allIngestorIds;
      if (chainIds.includes(source)) {
        scope = 'chain';
        type = 'error';
      } else if (nodeIds.includes(source)) {
        scope = 'node';
        type = 'error';
      } else if (ingestorIds.includes(source)) {
        scope = 'ingestor';
        type = 'warning';
      } else {
        type = 'warning';
      }
      const time = (new Date(raised_at)).getTime();
      const updated = (new Date(updated_at)).getTime();
      const cleared = (new Date(cleared_at)).getTime();
      let is_silenced = false;
      if (metric === "Ingestor_Not_Connected") {
        const ingestors = rootGetters.allIngestors;
        let matching_ingestors = ingestors.filter(i => i.id == source);
        if (matching_ingestors.length > 0) {
          is_silenced = matching_ingestors[0].is_silenced;
        }
      }
      else {
        const allNodes = rootGetters.allNodes;
        let nodes = allNodes.filter(node => node.id == source);
        if (nodes.length > 0) {
          let node = nodes[0];
          let alerts = node.alerts.filter(alert => alert.metric == metric);
          if (alerts.length > 0) {
            is_silenced = alerts[0].is_silenced;
          }
        }
      }
      const archived = !!cleared_at || is_silenced;
      state.notifications.push(
        {
          id,
          alert_id,
          scope,
          source,
          metric,
          url,
          type,
          criteria,
          error_value,
          text,
          time,
          latest_value,
          updated,
          cleared,
          archived,
          notifications_count});
    },
    updateNotification({state}, event_data) {
      console.log("updateNotification")
      const {id, updated_at, notifications: notifications_count } = event_data;
      const index = state.notifications.findIndex(notification => notification.id === id);
      if(index != -1) {
        Vue.set(state.notifications, index, {...state.notifications[index], notifications_count, updated_at});
      }
    },
		archiveNotification(context, id) {
      console.log("archiveNotification")
      for(var i=0; i<context.state.notifications.length; i++) {
        if(context.state.notifications[i].id == id) {
          context.state.notifications[i].archived = true;
          context.state.notifications[i].cleared = Math.floor(Date.now());
        }
      }
		},
		archiveNotifications(context, event_data) {
      console.log("archiveNotifications")
      const { source, metric, url } = event_data;
      for(var i=0; i<context.state.notifications.length; i++) {
        if(context.state.notifications[i].source == source && 
          context.state.notifications[i].metric == metric && 
          context.state.notifications[i].url == url &&
          !context.state.notifications[i].archived) {
          context.state.notifications[i].archived = true;
          context.state.notifications[i].cleared = Math.floor(Date.now());
        }
      }
    },
    archiveAll(context) {
      for(var i=0; i<context.state.notifications.length; i++) {
        context.state.notifications[i].archived = true;
      }
		},
    removeAllNotifications(context) {
      context.state.notifications = [];
    }
	},
	modules: {},
};
