<template>
    <span>{{ timeSinceText }}</span>
</template>

<script>
export default {
  props: {
    notification: Object,
  },
  data: () => ({
    now: Date.now(),
  }),
  created() {
    var self = this
    setInterval(function () {
      self.now = Date.now();
    }, 1000);
},
  computed: {
    seconds() {
      return Math.floor((this.now - this.notification.time) / 1000);
    },
    timeSinceText() {
      const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
      ];
      if ( this.seconds >= 1) {
        for (let i = 0; i < intervals.length; i++) {
          const interval = intervals[i];
          const count = Math.floor(this.seconds / interval.seconds);
          if (count >= 1) {
            return `${count} ${interval.label}${count !== 1 ? 's' : ''}`;
          }
        }
      } else {
        return "now";
      }
      return "now";
    }
  },
};
</script>