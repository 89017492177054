<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <node :mode=mode :node=node :chainId=chainId v-on:confirm="$emit('close')" v-on:cancel="$emit('close')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Node from "./Overlay/Node.vue";

export default {
  props: {
    chainId:String,
    mode:String,
    node:Object
  },
  components: {
    Node,
  },
  computed: {},
};
</script>

<style>
</style>