<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <alert-edit
          :alertSource="alertSource"
          :alertMetric="alertMetric"
          v-on:confirm="$emit('close')"
          v-on:cancel="$emit('close')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlertEdit from "./Overlay/AlertEdit.vue";

export default {
  props: {
    alertSource: String,
    alertMetric: String
  },
  components: {
    AlertEdit,
  },
  computed: {},
};
</script>

<style>
</style>