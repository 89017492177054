<template>
  <v-row>
    <v-col cols="auto" class="py-1">
      <span style="font-family: Inter; font-size: 0.875rem; font-weight: 600; margin-right: 4px;">
      {{ alert.metric.Title }}</span>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="grey-lighten-2" v-bind="attrs" v-on="on" small style="margin-top: -2px">
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span>{{ alert.metric.Description }}</span>
      </v-tooltip>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto" class="py-1"><span style="font-family: Inter; font-size: 0.875rem; font-weight: 400">{{ metricValue
    }}</span></v-col>
  </v-row>
</template>

<script>
import { formatMetricValue } from "../../services/metricService";

export default {
  props: {
    alert: Object,
    node: Object,
  },
  computed: {
    metricValue() {
      let value = this.$store.state.metrics.metric[this.alert.id]
      return formatMetricValue(
        this.alert.metric.Module,
        this.alert.metric.Name,
        value
      );
    },
  },
};
</script>
