export default {
	state: {
		lastMessageId: ""
	},
	mutations: {
		setLastMessageId(state, value) {
			state.lastMessageId = value;
		},
	},
	actions: {
		setLastMessageId(context, messageId) {
			context.commit('setLastMessageId', messageId);
		},
	},
	modules: {},
};
