<template>
  <v-card light class="rounded-md" width="1280px" height="640px">
    <v-container fluid style="height: 100%" class="rounded-md">
      <v-row style="height: 564px">
        <v-col class="pa-4" cols="4" style="height: 100%; border-right: solid 1px rgba(0,0,0,.12); overflow-y: scroll;">
          <v-text-field v-model="query" clearable hide-details outlined dense light color="black" class="mb-4" type="text"
            v-on:keyup.escape="clearSearch" v-on:click:clear="clearSearch" placeholder="Metric"
            prepend-inner-icon="mdi-magnify">
          </v-text-field>
          <v-list nav class="pa-0">
            <metric-module v-for="metricModule in metricModules" v-bind:key="metricModule.id"
              v-bind:metricModule="metricModule" v-bind:query="query" v-on:updateMetric="updateMetric"></metric-module>
          </v-list>
        </v-col>
        <v-col class="pa-4" cols="8" style="height: 100%; border-right: solid 1px rgba(0,0,0,.12); overflow-y: scroll;">
          <v-list nav class="pa-0">
            <alert-module v-for="metricModule in alertModules" v-bind:key="metricModule.id"
            v-bind:metricModule="metricModule" v-bind:query="query" v-on:updateMetric="updateMetric"></alert-module>
            <connect-and-latency-alerts-module
              v-if="showConnectsAlertsModule"
              v-bind:connectsAlerts="connectsAlerts"
              v-bind:query="query"
              v-on:update="updateAlertCondition" />
          </v-list>
        </v-col>
      </v-row>
      <v-row style="height: 76px; background-color: black;">
        <v-col class="pa-4" cols="auto">
          <v-menu offset-y top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn large depressed text class="ml-4 rounded-md no-active-btn white--text" v-bind="attrs" v-on="on">
                <v-icon style="margin-left: -4px">mdi-lightning-bolt</v-icon>
                <span style="font-family: Inter; text-transform: none; font-weight: 600; margin-top: -3px;">Actions</span>
              </v-btn>
            </template>
            <v-list class="mb-4" style="min-width: 240px; border-radius: 8px 8px 0px 0px">
              <v-list-item link v-for="action in actions" v-bind:key="action.id" v-on:click="performAction(action)">
                <v-icon class="mr-2" color="black">{{ action.icon }}</v-icon>
                <v-list-item-title>{{ action.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-4" cols="auto">
          <v-btn large depressed text class="ml-4 rounded-md no-active-btn white--text" v-on:click="$emit('cancel')">
            <span style="font-family: Inter; text-transform: none; font-weight: 600; margin-top: -3px;">Cancel</span>
          </v-btn>
          <v-btn depressed large color="white" class="rounded-md ml-4 black--text pr-3" v-on:click="save">
            <span style="font-family: Inter; text-transform: none; font-weight: 600; margin-top: -3px;">Save</span>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import MetricModule from './MetricsAndAlerts/MetricModule.vue'
import AlertModule from './MetricsAndAlerts/AlertModule.vue'
import ConnectAndLatencyAlertsModule from './MetricsAndAlerts/ConnectAndLatencyAlertsModule.vue'

export default {
  props: {
    node: Object,
  },
  components: {
    MetricModule,
    AlertModule,
    ConnectAndLatencyAlertsModule
  },
  data() {
    return {
      nodeCopy: null,
      metrics: {},
      query: "",
      actions: [
        { id: 0, name: "Enable all metrics", icon: "mdi-plus-network" },
        { id: 1, name: "Disable all metrics", icon: "mdi-minus-network" },
        { id: 2, name: "Enable all alerts", icon: "mdi-bell-plus" },
        { id: 3, name: "Disable all alerts", icon: "mdi-bell-minus" },
        { id: 4, name: "Use system defaults", icon: "mdi-backup-restore" }
      ],
      connectsAlerts: []
    };
  },
  computed: {
    showConnectsAlertsModule() {
      return this.connectsAlerts.filter(alert => {
        return "advanced".includes(this.query.toLowerCase()) || 
        alert.moduleMetric.Name.toLowerCase().includes(this.query.toLowerCase()) ||
        alert.alert.url.toLowerCase().includes(this.query.toLowerCase());
      }).length > 0;
    },
    metricModules() {
      return Object.keys(this.metrics).filter(moduleKey => {
        return Object.keys(this.metrics[moduleKey].metrics).filter(metricKey => {
          return this.metrics[moduleKey].name.toLowerCase().includes(this.query.toLowerCase()) || this.metrics[moduleKey].metrics[metricKey].information.Name.toLowerCase().includes(this.query.toLowerCase());
        }).length > 0;
      }).reduce((activeModules, moduleKey) => {
        activeModules[moduleKey] = this.metrics[moduleKey];
        return activeModules;
      }, {});
    },
    alertModules() {
      return Object.keys(this.metrics).filter(moduleKey => {
        return Object.keys(this.metrics[moduleKey].metrics).filter(metricKey => {
          return this.metrics[moduleKey].metrics[metricKey].configuration.monitoring && this.metrics[moduleKey].metrics[metricKey].information.Can_Alert
            && (this.metrics[moduleKey].name.toLowerCase().includes(this.query.toLowerCase()) || this.metrics[moduleKey].metrics[metricKey].information.Name.toLowerCase().includes(this.query.toLowerCase()))
        }).length > 0;
      }).reduce((activeModules, moduleKey) => {
        activeModules[moduleKey] = this.metrics[moduleKey];
        return activeModules;
      }, {});
    }
  },
  mounted() {
    if (this.node) {
      this.nodeCopy = this.node
      this.metrics = this.getAllModules(this.nodeCopy, this.nodeCopy.type);
      this.connectsAlerts = this.getAllConnectsAlerts();
    }
  },
  methods: {
    updateAlertCondition(alertId, condition) {
      let matchingAlerts = this
        .connectsAlerts
        .filter(ca => ca.alert.id == alertId);
      if (matchingAlerts.length == 0) {
        return;
      }
      matchingAlerts[0].alert.condition = condition;
    },
    getAllConnectsAlerts() {
      let moduleMetrics = this.$store.state.metrics.metrics2;
      moduleMetrics = moduleMetrics
        .map(moduleMetric => ({[`${moduleMetric.Module}/${moduleMetric.Name}`]: moduleMetric}));
      moduleMetrics = Object.assign({},
        ...moduleMetrics);
      let alerts = this.nodeCopy.alerts;
      return alerts
        .map(alert => {
          let moduleMetric = moduleMetrics[alert.metric];
          if (!moduleMetric.Url_Paths.includes(alert.url) && (moduleMetric.Name === "Connects" || moduleMetric.Name === "Latency")) {
            return {
              moduleMetric,
              alert: Object.assign({}, alert)
            };
          }
          return null;
        })
        .filter(alertInfo => !!alertInfo)
        .sort((a, b) => {
          if (a.moduleMetric.Sort_Order < b.moduleMetric.Sort_Order) {
            return -1;
          }
          if (a.moduleMetric.Sort_Order > b.moduleMetric.Sort_Order) {
            return +1;
          }
          return a.alert.url.localeCompare(b.alert.url);
        });
    },
    updateMetric(metric, configuration) {
      this.metrics[metric.Module].metrics[metric.Name].configuration = {
        alerting: configuration.alerting,
        condition: configuration.condition,
        monitoring: configuration.monitoring,
      };
    },
    async save() {
      await this.saveMetricsConfiguration(this.nodeCopy, this.metrics, this.connectsAlerts);
      this.$emit("confirm");
    },
    clearSearch() {
      this.query = "";
    },
    enableAllMetrics() {
      Object.keys(this.metrics).forEach(moduleKey => {
        Object.keys(this.metrics[moduleKey].metrics).forEach(metricKey => {
          let metric = this.metrics[moduleKey].metrics[metricKey]
          let information = metric.information
          let configuration = metric.configuration
          if (information.Can_Manually_Enable) {
            this.updateMetric(information, {
              monitoring: true,
              alerting: configuration.alerting,
              condition: configuration.condition
            })
          }
        })
      })
    },
    disableAllMetrics() {
      Object.keys(this.metrics).forEach(moduleKey => {
        Object.keys(this.metrics[moduleKey].metrics).forEach(metricKey => {
          let metric = this.metrics[moduleKey].metrics[metricKey]
          let information = metric.information
          if (information.Can_Manually_Enable) {
            this.updateMetric(information, {
              monitoring: false,
              alerting: false,
              condition: ""
            })
          }
        })
      })
    },
    enableAllAlerts() {
      Object.keys(this.metrics).forEach(moduleKey => {
        Object.keys(this.metrics[moduleKey].metrics).forEach(metricKey => {
          let metric = this.metrics[moduleKey].metrics[metricKey]
          let information = metric.information
          let configuration = metric.configuration
          if (configuration.alerting) {
            this.updateMetric(information, {
              monitoring: true,
              alerting: true,
              condition: configuration.condition
            })
          } else {
            this.updateMetric(information, {
              monitoring: true,
              alerting: true,
              condition: information.Condition_Default
            })
          }
        })
      })
    },
    disableAllAlerts() {
      Object.keys(this.metrics).forEach(moduleKey => {
        Object.keys(this.metrics[moduleKey].metrics).forEach(metricKey => {
          let metric = this.metrics[moduleKey].metrics[metricKey]
          let information = metric.information
          let configuration = metric.configuration
          this.updateMetric(information, {
            monitoring: true,
            alerting: false,
            condition: configuration.condition
          })
        })
      })
    },
    useAlertDefaults() {
      this.metrics = this.getDefaultSettings(this.nodeCopy.type);
    },
    performAction(action) {
      switch (action.id) {
        case 0:
          this.enableAllMetrics();
          break;
        case 1:
          this.disableAllMetrics();
          break;
        case 2:
          this.enableAllAlerts()
          break;
        case 3:
          this.disableAllAlerts()
          break;
        case 4:
          this.useAlertDefaults()
          break;
      }
    }
  },
};
</script>

<style></style>