<template>
  <v-card width="350px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: black; text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px">mdi-pencil</v-icon>
        Choose Metrics
      </span>
    </v-card-title>
    <v-col class="pa-6" style="max-height: 480px; overflow-y: scroll;">
      <v-checkbox
        v-for="metric in allMetrics"
        v-bind:key="metric.Name"
        v-bind:value="metric.Name"
        v-model="selectedMetrics"
        color="black"
        class="mt-0"
      >
        <template v-slot:label>
          <v-card-text class="pa-0" color="black">
            <span style="font-family: Inter; font-weight: 600; color: black">
              {{ metric.Title }}
            </span></v-card-text
          >
        </template>
      </v-checkbox>
    </v-col>
    <v-col class="pa-6">
      <div style="text-align: right">
        <v-btn
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn"
          v-on:click="$emit('cancel')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Cancel</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          color="black"
          class="rounded-md ml-4 white--text pr-3"
          v-on:click="save"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Save</span
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  props: {
    table: Object,
    chain: Object,
  },
  data: () => ({
    selectedMetrics: [],
    allMetrics: [],
  }),
  mounted() {
    this.allMetrics = this.$store.state.metrics.metrics2.filter((metric) => {
      return metric.Module == this.table.module;
    })
    this.allMetrics.unshift({
      Module: this.table.module,
      Name: "Address",
      Title: "Address"
    });
    this.selectedMetrics = this.table.metrics;
  },
  methods: {
    ...mapMutations(["updateTable"]),
    ...mapActions(["saveTables"]),
    save() {
      let sortedSelectedMetrics = this.allMetrics.filter((metric) => {
        return this.selectedMetrics.includes(metric.Name);
      }).map(metric => metric.Name);
      this.updateTable({chain: this.table.chain, module: this.table.module, metrics: sortedSelectedMetrics});
      this.saveTables();
      this.$emit('confirm');
    }
  },
};
</script>

<style>
</style>