export default {
	state: {
		chains: [
		]
	},
	getters: {
		getChains(state) {
			return state.chains;
		},
		allChainIds(state) {
			const ids = [];
			state.chains.forEach(chain => {
				ids.push(chain.chain_id);
			});
			return ids;
		},
		allNodeIds(state) {
			const ids = [];
			state.chains.forEach(chain => {
				chain.nodes.forEach(node => {
					ids.push(node.id);
				})
			});
			return ids;
		},
		allNodes(state) {
			const ids = [];
			state.chains.forEach(chain => {
				chain.nodes.forEach(node => {
					ids.push(node);
				})
			});
			return ids;
		}
	},
	mutations: {
		initialiseChains(state, value) {
			state.chains = value;
		}
	},
	actions: {
		initialiseChains(context, chains) {
			context.commit('initialiseChains', chains);
		},
	},
	modules: {},
};
