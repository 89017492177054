<template>
    <v-col v-if="blockProducer" cols="auto" class="px-3">
        <chain-b-p-bar-vote-rank v-bind:blockProducer="blockProducer" />
        <chain-b-p-bar-head-block-producer v-bind:blockProducer="blockProducer" />
        <chain-b-p-bar-head-block v-bind:blockProducer="blockProducer" />
    </v-col>
</template>

<script>
import ChainBPBarVoteRank from './ChainBPBarVoteRank.vue';
import ChainBPBarHeadBlock from './ChainBPBarHeadBlock.vue';
import ChainBPBarHeadBlockProducer from './ChainBPBarHeadBlockProducer.vue';

export default {
    props: {
        blockProducer: Object,
    },
    components: {
        ChainBPBarVoteRank,
        ChainBPBarHeadBlock,
        ChainBPBarHeadBlockProducer,
    }
}
</script>