<template>
  <v-card width="350px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: black; text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px">mdi-plus</v-icon>
        {{ mode }} Ingestor
      </span>
    </v-card-title>
    <v-col class="pa-6">
      <v-card-text class="pa-0" color="black">
        <span style="font-family: Inter; font-weight: 600">
          Ingestor Name
        </span></v-card-text
      >
      <v-text-field
        v-model="name"
        outlined
        dense
        light
        color="black"
        class="mb-6"
        type="text"
        placeholder="Ingestor 1"
        :rules="[rules.notEmpty]"
      >
      </v-text-field>
      <v-card-text class="pa-0" color="black">
        <span style="font-family: Inter; font-weight: 600">
          Ingestor Sort Order
        </span></v-card-text
      >
      <v-text-field
        v-model="sort_order"
        outlined
        dense
        light
        color="black"
        class="mb-6"
        type="text"
        placeholder="0"
        :rules="[rules.nonNegativeInteger]"
      >
      </v-text-field>      
      <div style="text-align: right">
        <v-btn
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn"
          v-on:click="$emit('cancel')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Cancel</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          color="black"
          class="rounded-md ml-4 white--text pr-3"
          v-on:click="callAddIngestor"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >{{ okButtonCaption }}</span
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import { EditDialogModeEnum } from '../../../enums/editDialogModeEnum';
import axios from 'axios';
import { nonNegativeInteger, notEmpty } from "../../../services/rulesService";

export default {
  props: {
    ingestor: Object,
    mode: String
  },
  data() {
    return {
      id: "",
      name: "",
      sort_order: 0,
      rules: { nonNegativeInteger, notEmpty } 
    }
  },
  computed: {
    okButtonCaption() {
      return this.mode == EditDialogModeEnum.Add ? "Create" : "Save";
    }
  },
  mounted() {
    this.id = this.ingestor.id;
    this.name = this.ingestor.name;
    this.sort_order = this.ingestor.sort_order;
  },
  methods: {
    async callAddIngestor() {
      let payload = { 
        id: this.id,
        name: this.name,
        sort_order: this.sort_order,
        is_silenced: false
      };
      await axios.post('/api/v1/ingestor/', payload);
      this.$emit("confirm");
    },
  }
}
</script>

<style>
</style>