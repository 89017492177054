<template>
    <v-card outlined class="rounded-lg" v-bind:color="accentColor" v-bind:style="{ 'border-color': accentColor }">
      <v-container v-if="display != 'home' && display!='historical'" class="pa-2 px-6">
        <v-row d-flex>
          <v-col v-if="!this.notification.archived" cols="auto">
            <span style="color: white; text-align: center;">
              <v-icon dark size="small" style="vertical-align: middle" class="mr-1">mdi-timer-outline</v-icon>
              <span style="
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                vertical-align: middle;
              "><notification-time-since v-bind:notification="notification" /></span>
            </span>
          </v-col>
          <v-col cols="auto">
            <div style="color: white; text-align: center;">
                <v-icon dark size="small" style="vertical-align: middle" class="mr-1">mdi-calendar</v-icon>
                <span style="
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 400;
                  vertical-align: middle;
                ">{{ toDateHumanFormat(notification?.time) }}</span>
              </div>
          </v-col>
          <v-col cols="auto">
            <div style="color: white; text-align: center;">
                <v-icon dark size="small" style="vertical-align: middle" class="mr-1">mdi-clock-outline</v-icon>
                <span style="
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 400;
                  vertical-align: middle;
                ">{{ toTimeHumanFormat(notification?.time) }}</span>
              </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="px-0">
              <v-btn small icon color="white" v-on:click="goToSource()">
                <v-icon dark size="small" style="vertical-align: middle">mdi-cube-outline</v-icon>
              </v-btn>
          </v-col>
          <v-col v-if="display != 'alert'" cols="auto" class="px-0">
            <v-btn small icon color="white" v-on:click="silenceAlert(notification)">
                <v-icon dark size="small" style="vertical-align: middle">mdi-bell-off</v-icon>
              </v-btn>
          </v-col>
          <v-col v-if="display != 'alert'" cols="auto" class="px-0">
            <v-btn small icon color="white" v-on:click="goToNotification">
              <v-icon dark size="small" style="vertical-align: middle">mdi-open-in-new</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card class="rounded-lg" v-bind:style="{ 'border-color': accentColor, 'box-shadow': shadow, color: accentColor}">
        <v-container class="pa-6">
        <v-row d-flex>
          <v-col cols="auto" align-self="center">
            <span
              v-bind:style="{ 'background-color': accentColor }"
              style="display: flex; align-items: center; justify-content: center; padding: 8px; border-radius: 8px; font-size: 20px; font-weight: 600;">
              <v-icon large color="white">{{ icon }}</v-icon>
            </span>
          </v-col>
          <v-col cols="auto" align-self="center" class="pr-0" style="max-width: calc(100% - 140px);">
            <v-container class="py-0">
              <v-row v-if="scope=='Ingestor'" align="center">
                <v-col cols="auto" class="pa-0" style="cursor: pointer">
                  <div>
                    <span style="
                      font-family: Inter;
                      text-transform: uppercase;
                      font-size: 10px;
                    ">{{ ingestorName }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else align="center">
                <v-col cols="auto" class="pa-0" v-on:click="toChain()" style="cursor: pointer">
                  <div>
                    <span style="
                      font-family: Inter;
                      text-transform: uppercase;
                      font-size: 10px;
                    ">{{ chainName }}</span>
                  </div>
                </v-col>
                <v-col v-if="scope == 'Node'" cols="auto" class="pa-0">
                  <div>
                    <span style="
                      font-family: Inter;
                      text-transform: uppercase;
                      padding-left: 4px;
                      padding-right: 4px;
                      font-size: 10px;
                    ">
                      /
                    </span>
                  </div>
                </v-col>
                <v-col v-if="scope == 'Node'" cols="auto" class="pa-0" v-on:click="toNode()" style="cursor: pointer">
                  <div>
                    <span style="font-family: Inter; text-transform: uppercase; 
                      font-size: 10px;">{{ nodeName }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <div style="font-family: Inter; font-size: 14px;">
                    {{ notification?.text }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" align-self="center">
            <v-btn v-if="display=='home'" v-on:click="goToSource()" icon v-bind:color="accentColor">
              <v-icon large style="margin">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn v-else-if="display=='historical'" v-on:click="goToNotification()" icon v-bind:color="accentColor">
              <v-icon large style="margin">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn v-else v-on:click="showDetails = !showDetails" icon v-bind:color="accentColor">
              <v-icon v-if="showDetails" large style="margin">mdi-chevron-up</v-icon>
              <v-icon v-else large>mdi-chevron-down</v-icon>
            </v-btn>
            
          </v-col>
        </v-row>
      </v-container>
      </v-card>
      <v-col v-if="showDetails" class="pa-6 pb-7 pt-9 mt-n2" v-bind:style="{backgroundColor: backgroundColor, color: accentColor}">
        <v-row v-for="detail in unclearedDetails" v-bind:key="detail.field">
          <v-col cols="auto" class="py-1"
            ><span
              style="font-family: Inter; font-size: 0.875rem; font-weight: 600"
              >{{ detail.field }}</span
            ></v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="auto" class="py-1"
            ><span
              style="font-family: Inter; font-size: 0.875rem; font-weight: 400"
              >{{ detail.value }}</span
            ></v-col
          >
        </v-row>
      </v-col>
      </v-card>
</template>

<script>
import NotificationTimeSince from "./NotificationTimeSince.vue";

import { mapGetters } from "vuex";

import { encodeQuery } from '../../utils/queryParams';
import { formatMetricValue } from "../../services/metricService";

export default {
  components: {
    NotificationTimeSince,
  },
  props: {
    notification: Object,
    display: String,
    show: Object
  },
  data: () => ({
    showDetails: false
  }),
  computed: {
    ...mapGetters(["allIngestorIds", "allChainIds", "allNodeIds", "allIngestors", "allNodes"]),
    node() {
      if (this.scope == 'Node') {
        let search = this.allNodes.filter((node) => {
          return node.id == this.sourceId;
        })
        if (search.length > 0) {
          return search[0]
        }
      }
      return null;
    },
    chain() {
      if (this.node != null) {
        let search = this.$store.state.chain.chains.filter((chain) => {
          return chain.id == this.node.chain_id;
        })
        if (search.length > 0) {
          return search[0]
        }
      }
      return null;
    },
    alertId() {
      return this.notification?.id;
    },
    sourceId() {
      return this.notification?.source;
    },
    scope() {
      return this.notification?.scope.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    ingestorName() {
      return "Ingestor 1";
    },
    chainName() {
      return this.chain?.name;
    },
    nodeName() {
      return this.node?.name;
    },
    metricName() {
      return this.notification?.metric;
    },
    metricModule() {
      return this.metricName?.split('/')[0];
    },
    metricMetric() {
      return this.metricName?.split('/')[1];
    },
    metricValue() {
      return this.$store.state.metrics.metric[`${this.node.id}/${this.metricName}`];
    },
    alertText() {
      return this.notification?.text;
    },
    alertCreated() {
      return this.notification.time ? this.toDateHumanFormat(this.notification.time) + " " + this.toTimeHumanFormat(this.notification.time) : null;
    },
    alertUpdated() {
      return this.notification.updated ? this.toDateHumanFormat(this.notification.updated) + " " + this.toTimeHumanFormat(this.notification.updated): null;
    },
    alertCleared() {
      return this.notification.cleared ? this.toDateHumanFormat(this.notification.cleared) + " " + this.toTimeHumanFormat(this.notification.cleared): null;
    },
    latestValue() {
      return formatMetricValue(
        this.metricModule,
        this.metricMetric,
        this.notification?.latest_value
      );
    },
    errorValue() {
      return formatMetricValue(
        this.metricModule,
        this.metricMetric,
        this.notification?.error_value
      );
    },
    criteria() {
      return formatMetricValue(
        this.metricModule,
        this.metricMetric,
        this.notification?.criteria
      );
    },
    metricCurrent() {
      return formatMetricValue(
        this.metricModule,
        this.metricMetric,
        this.metricValue
      );
    },
    detailsSection() {
      return this.display!="home";
    },
    unclearedDetails() {
      return this.details.filter(detail => !(detail.field=='Alert Cleared' && !detail.value))
    },
    details() {
      if (this.scope == "Node") {
        return [
          {
            field: "Alert ID",
            value: this.alertId
          },
          {
            field: "Scope",
            value: this.scope
          },
          {
            field: "Chain",
            value: this.chainName
          },
          {
            field: "Node",
            value: this.nodeName
          },
          {
            field: "Metric",
            value: this.metricName
          },
          {
            field: "Alert",
            value: this.alertText
          },
          {
            field: "Criteria",
            value: this.criteria
          },
          {
            field: "Value Recorded",
            value: this.errorValue
          },
          {
            field: "Alert Created",
            value: this.alertCreated
          },
          {
            field: "Current Value",
            value: this.metricCurrent
          },
          {
            field: "Alert Cleared",
            value: this.alertCleared
          },
        ];
      } else if (this.scope == "Ingestor") {
        return [
          {
            field: "Alert ID",
            value: this.alertId
          },
          {
            field: "Scope",
            value: this.scope
          },
          {
            field: "Ingestor",
            value: this.ingestorName
          },
          {
            field: "Alert",
            value: this.alertText
          },
          {
            field: "Alert Created",
            value: this.alertCreated
          },
          {
            field: "Alert Cleared",
            value: this.alertCleared
          },
        ];
      } else {
        return [];
      }
    },
    backgroundColor() {
      if (this.notification?.archived) return "hsl(0, 0%, 94%)";
      switch (this.notification?.type) {
        case "success":
          return "hsl(120, 67%, 94%)";
        case "information":
          return "hsl(220, 87%, 94%)";
        case "warning":
          return "hsl(40, 87%, 94%)";
        case "error":
          return "hsl(0, 87%, 94%)";
        default:
          return "hsl(0, 0%, 94%)";
      }
    },
    accentColor() {
      if (this.notification?.archived) return "hsl(0, 0%, 59%)";
      switch (this.notification?.type) {
        case "success":
          return "hsl(120, 67%, 39%)";
        case "information":
          return "hsl(220, 87%, 49%)";
        case "warning":
          return "hsl(40, 87%, 49%)";
        case "error":
          return "hsl(0, 87%, 59%)";
        default:
          return "hsl(0, 0%, 59%)";
      }
    },
    shadow() {
      if (this.notification?.archived) return "0px 0px 6px hsla(0, 0%, 59%, 0.75)";
      switch (this.notification?.type) {
        case "success":
          return "0px 0px 6px hsla(120, 87%, 39%, 0.75)";
        case "information":
          return "0px 0px 6px hsla(220, 87%, 49%, 0.75)";
        case "error":
          return "0px 0px 6px hsla(0, 87%, 59%, 0.75)";
        case "warning":
          return "0px 0px 6px hsla(39, 87%, 49%, 0.75)"
        default:
          return "0px 0px 6px hsla(0, 0%, 59%, 0.75)"
      }
    },
    icon() {
      switch (this.notification?.type) {
        case "success":
          return "mdi-check-circle-outline";
        case "information":
          return "mdi-information-slab-circle-outline";
        case "warning":
          return "mdi-alert-outline"
        case "error":
          return "mdi-alert-circle-outline";
        default:
          return "mdi-bell-outline"
      }
    }
  },
  methods: {
    toChain() {
      if( this.notification.scope == "node" ) {
        this.$router.push(`/chain/${this.node.chain_id}`).catch(() => {});
      } else if( this.notification.scope == "chain") {
        this.$router.push(`/chain/${this.notification.source}`).catch(() => {});
      }
    },
    toNode() {
      if( this.notification.scope == "node" ) {
        this.$router.push(`/node/${this.node.chain_id}/${this.node.id}`).catch(() => {});
      }
    },
    goToSource() {
      if( this.notification.scope == "node" ) {
        this.$router.push(`/node/${this.node.chain_id}/${this.node.id}`).catch(() => {});
      } else if( this.notification.scope == "chain") {
        this.$router.push(`/chain/${this.notification.source}`).catch(() => {});
      }
    },
    goToNotification() {
      let queryParams = {
        metric: this.notification.metric,
        source: this.notification.source
      }
      this.$router.push(`/alert?${encodeQuery(queryParams)}`);
    },
    silenceAlert() {
      let alertSourceId = {
        metric: this.notification?.metric,
        source: this.notification?.source
      };
      this.$emit('editAlert', alertSourceId)
    }
  },
};
</script>