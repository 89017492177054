<template>
  <v-card width="400px" light class="rounded-md">
    <v-form ref="editForm" v-model="valid" @submit="callAddNode" @submit.prevent>
      <v-card-title
        primary-title
        style="background-color: black; text-align: left"
        class="pa-6"
        color="white"
      >
        <span style="font-family: Inter; color: white" class="text-h5">
          <v-icon v-if="isAddMode" color="white" style="margin-top: -5px"
            >mdi-plus</v-icon
          >
          <v-icon v-else color="white" style="margin-top: -3px">mdi-cog</v-icon>
          {{ mode }} Node
        </span>
      </v-card-title>
      <v-col class="pa-6">
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Node Name
          </span></v-card-text
        >
        <v-text-field
          v-model="name"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="Node 1"
          :rules="[rules.notEmpty]"
        >
        </v-text-field>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Node Type
          </span></v-card-text
        >
        <v-select
          v-bind:items="nodeTypes"
          v-model="type"
          single-line
          outlined
          label="Node Type"
          dense
          color="black"
          :rules="[rules.notZero]"
        ></v-select>
        <v-row>
          <v-col cols="6">
            <v-card-text class="pa-0" color="black">
              <span style="font-family: Inter; font-weight: 600">
                IP Adress
              </span></v-card-text
            >
            <v-text-field
              v-model="ip"
              outlined
              dense
              light
              color="black"
              class="mb-6"
              type="text"
              placeholder="127.0.0.1"
              :rules="[rules.notEmpty]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-card-text class="pa-0" color="black">
              <span style="font-family: Inter; font-weight: 600">
                Port
              </span></v-card-text
            >
            <v-text-field
              v-model="port"
              outlined
              dense
              light
              color="black"
              class="mb-6"
              type="text"
              placeholder="3000"
              :rules="[rules.nonNegativeInteger]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Sort Order
          </span></v-card-text
        >
        <v-text-field
          v-model="sort_order"
          outlined
          dense
          light
          color="black"
          class="mb-6"
          type="text"
          placeholder="Sort Order"
          :rules="[rules.nonNegativeInteger]"
        ></v-text-field>
        <v-checkbox v-model="ssl" color="black" class="mt-0">
          <template v-slot:label>
            <v-card-text class="pa-0" color="black">
              <span style="font-family: Inter; font-weight: 600; color: black">
                Use SSL for connections
              </span></v-card-text
            >
          </template>
        </v-checkbox>
        <v-card-text class="pa-0" color="black">
          <span style="font-family: Inter; font-weight: 600">
            Ingestor
          </span></v-card-text
        >
        <v-select
          v-bind:items="ingestors"
          v-model="ingestor"
          single-line
          outlined
          label="Ingestor"
          dense
          color="black"
        ></v-select>
        <div style="text-align: right">
          <v-btn
            large
            depressed
            text
            class="ml-4 rounded-md no-active-btn"
            v-on:click="$emit('cancel')"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >Cancel</span
            >
          </v-btn>
          <v-btn
            v-if="isEditMode"
            depressed
            large
            color="red"
            class="rounded-md ml-4 white--text pr-3"
            v-on:click="callDeleteNode"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >Delete</span
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            depressed
            large
            color="black"
            class="rounded-md ml-4 white--text pr-3"
            type="submit"
          >
            <span
              style="
                font-family: Inter;
                text-transform: none;
                font-weight: 600;
                margin-top: -3px;
              "
              >{{ okButtonCaption }}</span
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

import { EditDialogModeEnum } from "../../../enums/editDialogModeEnum";
import { nodeTypeDict, nodeTypeDictReversed } from '../../../services/nodeTypeService';
import errorUtility from "../../../services/errorUtility";
import { nonNegativeInteger, notEmpty, notZero } from "../../../services/rulesService";

import { mapActions } from "vuex";

export default {
  props: {
    chainId: String,
    mode: String,
    node: Object,
  },
  data() {
    return {
      id: "",
      name: "",
      ip: "",
      port: "",
      type: null,
      ssl: false,
      sort_order: 0,
      ingestor: "",
      rules: { nonNegativeInteger, notEmpty, notZero },
      valid: true
    };
  },
  computed: {
    nodeTypes() {
      return Object.keys(nodeTypeDict);
    },
    okButtonCaption() {
      return this.mode == EditDialogModeEnum.Add ? "Create" : "Save";
    },
    ingestors() {
      return this.$store.state.ingestors.ingestors.map((ingestor) => ingestor.name).concat(["(None)"])
    },
    isAddMode() {
      return this.mode == EditDialogModeEnum.Add;
    },
    isEditMode() {
      return this.mode == EditDialogModeEnum.Edit;
    },
    chain() {
      return this.chainId ? this.$store.state.chain.chains.filter(
        (chain) => chain.id === this.chainId
      )[0] : null;
    },
    node_created() {
      return this.chain && this.id ? this.chain.nodes.filter((node) => node.id === this.id)[0] : null;
    },
    alerts() {
      return this.node_created?.alerts;
    }
  },
  mounted() {
    this.id = this.node.id;
    this.name = this.node.name;
    this.ip = this.node.network_address;
    this.port = this.node.port;
    this.ssl = this.node.ssl;
    this.type = nodeTypeDictReversed[this.node.type];
    this.sort_order = this.node.sort_order;
    this.ingestor = this.ingestors[0];
  },
  methods: {
    ...mapActions(["initialiseTables"]),
    async callAddNode() {
      if (!this.$refs.editForm.validate()) { return; }
      let ingestor_id = null;
      if (this.ingestor != "(None)") {
        ingestor_id = this.$store.state.ingestors.ingestors.filter(
          (ingestor) => ingestor.name == this.ingestor
        )[0].id;
      }

      let payload = {
        id: this.id,
        name: this.name,
        network_address: this.ip,
        port: this.port,
        ssl: !!this.ssl,
        type: nodeTypeDict[this.type],
        chain_id: this.chainId,
        active: true,
        sort_order: this.sort_order,
        ingestor_id: ingestor_id,
      };
      await axios.post("/api/v1/node/", payload);
      await this.initialiseTables();
      await this.applyDefaults();
      this.$emit("confirm");
      let nodeUrl = `/node/${this.chainId.toString()}/${this.id}`;
      this.$router.push(nodeUrl);
    },
    async applyDefaults() {
      let defaults = this.getDefaultSettings(this.node_created.type)
      await this.saveMetricsConfiguration(this.node_created, defaults, [])
      
      let connect_latency_alerts = [];
      this.alerts.forEach(alert => {
        let moduleName = alert.metric.split('/')[0];
        let metricName = alert.metric.split('/')[1];
        let metric = defaults[moduleName].metrics[metricName];
        if (!metric.information.Can_Manually_Enable && metric.information.Url_Paths.includes(alert.url)) {
          connect_latency_alerts.push({
            alert: {
            id: alert.id,
            condition: metric.information.Alerting_Default.includes(this.node_created.type)
          }})
        }
      });
      this.saveMetricsConfiguration(this.node_created, defaults, connect_latency_alerts);
    },
    async callDeleteNode() {
      if (confirm("Are you sure you want to delete this node?") == false) {
        return;
      }
      try {
        await axios.delete(`/api/v1/node/${this.id}`);
        this.initialiseTables();
        let chainUrl = `/chain/${this.chainId.toString()}`;
        this.$router.push(chainUrl);
        this.$emit("confirm");
      } catch (e) {
        errorUtility.handleServerError(e);
      }
    }
  },
};
</script>

<style>
</style>