'use strict';

import axios from 'axios';
import * as Sentry from "@sentry/browser";

async function login(password)
{
    let payload = {
        password: password
    }
    try {
        let resp = await axios.post('/api/v1/login/', payload);
        let success = resp.data.result;
        let accessToken = '';
        if (success)
        {
            accessToken = resp.data.access_token;
        }
        return { success, accessToken };
      }
      catch (err) {
        Sentry.captureException(err);
        return { success: false, accessToken: '' };
      }
}

async function getLoginStatus()
{
    try {
        let resp = await axios.get('/api/v1/login/status');
        let success = resp.data.result;
        let accessToken = '';
        if (success)
        {
            accessToken = resp.data.access_token;
        }
        return { success, accessToken };
    }
    catch (err) {
        Sentry.captureException(err);
        return { success: false, accessToken: '' };
    }
}

export default {
    login,
    getLoginStatus
} 
