<template>
  <v-container class="pa-6" style="max-width: 1680px;">
    <notification v-for="notification in this.notifications" v-bind:key="notification.id"
      v-bind:notification="notification" v-bind:display="'node'" v-on:editAlert="editAlert" class="mb-6" />
    <v-row align="center">
      <v-col cols="auto" class="pr-0">
        <v-btn v-on:click="toChain()" icon outlined color="grey" style="background-color: 'rgba(0, 0, 0, 0.05)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="pr-0" v-on:click="toChain()" style="cursor: pointer">
        <v-img min-height="24" max-width="24" contain v-bind:src="this.chainIcon"
          style="margin-bottom: 2px; margin-top: -2px"></v-img>
      </v-col>
      <v-col cols="auto" class="pr-0" v-on:click="toChain()" style="cursor: pointer">
        <div class="text-h5">
          <span style="
              font-family: Inter;
              text-transform: uppercase;
              font-weight: 600;
            ">{{ chainTitle }}</span>
        </div>
      </v-col>
      <v-col cols="auto" class="px-0">
        <div class="text-h5">
          <span style="
              font-family: Inter;
              text-transform: uppercase;
              padding-left: 8px;
              padding-right: 8px;
            ">
            /
          </span>
        </div>
      </v-col>
      <v-col cols="auto" class="pl-0">
        <div class="text-h5">
          <span style="font-family: Inter; text-transform: uppercase">{{
            nodeTitle
          }}</span>
        </div>
      </v-col>
      <v-col cols="auto" class="pl-0">
        <v-chip outlined v-bind:color="nodeTypeColor" label style="
            background-color: white !important;
            text-transform: capitalize;
            height: 36px;
          ">
          {{ nodeType }}
        </v-chip>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-chip v-if="thisNodeStatus == 'active'" light label outlined class="rounded-pill"
          style="height: 36px; background-color: hsl(120, 67%, 94%) !important; color: hsl(120, 67%, 39%) !important; border-color: hsl(120, 67%, 39%) !important;">
          <v-icon color="hsl(120, 67%, 39%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(120, 67%, 39%)">Active</span>
        </v-chip>
        <v-chip v-else-if="thisNodeStatus == 'errors'" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(40, 87%, 94%) !important; color: hsl(40, 87%, 49%) !important; border-color: hsl(40, 87%, 49%) !important;">
          <v-icon color="hsl(40, 87%, 49%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(40, 87%, 49%)">Errors</span>
        </v-chip>
        <v-chip v-if="thisNodeStatus == 'disconnected'" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(0, 87%, 94%) !important; color: hsl(0, 87%, 59%) !important; border-color: hsl(0, 87%, 59%) !important;">
          <v-icon color="hsl(0, 87%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 87%, 59%)">Disconnected</span>
        </v-chip>
        <v-chip v-else-if="thisNodeStatus == 'not connected'" light label outlined class="rounded-pill ml-3"
          style="height: 36px; background-color: hsl(0, 0%, 94%) !important; color: hsl(0, 0%, 59%) !important; border-color: hsl(0, 0%, 59%) !important;">
          <v-icon color="hsl(0, 0%, 59%)" class="mr-1">mdi-circle</v-icon>
          <span style="font-family: Inter; color: hsl(0, 0%, 59%)">Not Connected</span>
        </v-chip>
      </v-col>
      <v-col v-if="authStatus" cols="auto" class="pl-0">
        <v-btn v-on:click="openNodeEditOverlay" icon outlined color="grey" style="background-color: 'rgba(0, 0, 0, 0.05)">
          <v-icon small>mdi-cog</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="authStatus" cols="auto" class="pl-0">
        <v-btn v-on:click="openMetricsAndAlertsOverlay" icon outlined color="grey"
          style="background-color: 'rgba(0, 0, 0, 0.05)">
          <v-icon small>mdi-bell-cog</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <address-component v-bind:node="node" />
        <endpoints v-bind:node="node" class="mt-6"/>
      </v-col>
      <v-col cols="4">
        <activity v-bind:node="node" />
      </v-col>
      <v-col cols="4">
        <information v-bind:node="node" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddressComponent from "../components/Node/Address.vue";
import Activity from "../components/Node/Activity.vue";
import Endpoints from "../components/Node/Endpoints.vue";
import Information from "../components/Node/Information.vue";
import Notification from "../components/Notifications/Notification.vue";
import { nodeTypeDictReversed } from "../services/nodeTypeService";

export default {
  components: {
    AddressComponent,
    Activity,
    Information,
    Endpoints,
    Notification
  },
  data: () => ({}),
  computed: {
    activeMetricValue() {
      return this.$store.state.metrics.metric[`${this.node.id}/Node/Connects`];
    },
    atomicActiveMetricValue() {
      return this.$store.state.metrics.metric[
        `${this.node.id}/AtomicAssets/Connects`
      ];
    },
    hyperionActiveMetricValue() {
      return this.$store.state.metrics.metric[
        `${this.node.id}/Hyperion/Connects`
      ];
    },
    websiteBpJsonConnects() {
      return this.$store.state.metrics.metric[
        `${this.node.id}/Website/ChainsJsonAndBpJson`
      ];
    },
    authStatus() {
      return this.$store.state.auth.status;
    },
    chainId() {
      return this.$route.params.chain;
    },
    chain() {
      return this.$store.state.chain.chains.filter(
        (chain) => chain.id === this.$route.params.chain
      )[0];
    },
    chainTitle() {
      return this.chain ? this.chain.name : "";
    },
    chainIcon() {
      return this.chain ? this.chain.icon : "";
    },
    nodeId() {
      return this.$route.params.node;
    },
    node() {
      return this.chain
        ? this.chain.nodes.filter((node) => node.id === this.nodeId)[0]
        : null;
    },
    nodeKey() {
      return this.nodeId + this.nodeChainId;
    },
    nodeChainId() {
      return this.node ? this.node.chain_id : null;
    },
    nodeTitle() {
      return this.node ? this.node.name : "";
    },
    nodeType() {
      let nodeType = this.node ? this.node.type : null;
      if (nodeType in nodeTypeDictReversed) {
        return nodeTypeDictReversed[nodeType];
      }
      return "Unknown";
    },
    nodeTypeColor() {
      if (this.nodeType === "Block Producer") return "#0d53ee";
      else if (this.nodeType === "Full Node") return "#45e09e";
      else return "#479acc";
    },
    thisNodeStatus() {
      return this.nodeStatus(this.node);
    },
    lastMessageId() {
      return this.$store.state.messages.lastMessageId;
    },
    notifications() {
      return this.$store.state.notifications.notifications
        .filter((notification) => {
          return (
            notification.scope === "node" &&
            notification.source === this.getNodeId() &&
            !notification.archived &&
            notification.notifications_count > 0
          );
        })
        .reverse();
    },
  },
  methods: {
    openNodeEditOverlay() {
      this.$emit("editNode", { chainId: this.chainId, nodeId: this.nodeId });
    },
    getChainId() {
      return this.$route.params.chain;
    },
    getChain() {
      return this.$store.state.chain.chains.filter(
        (chain) => chain.id === this.getChainId()
      )[0];
    },
    getNodeId() {
      return this.$route.params.node;
    },
    getNode() {
      return this.getChain().nodes.filter(
        (node) => node.id === this.getNodeId()
      )[0];
    },
    openMetricsAndAlertsOverlay() {
      this.$emit("editMetricsAndAlerts", {
        chainId: this.chainId,
        nodeId: this.nodeId,
      });
    },
    editAlert(e) {
      this.$emit('editAlert', e)
    },
    toChain() {
      let chainUrl = `/chain/${this.chainId.toString()}`;
      this.$router.push(chainUrl).catch(() => { });
    },
  },
};
</script>

<style></style>