<template>
    <chain-table-cell
      v-bind:width="width"
      v-bind:value="value"
    />
</template>

<script>
import ChainTableCell from "./ChainTableCell.vue";
import { formatMetricValue } from "../../services/metricService";


export default {
  props: {
    metric: Object,
    node: Object,
    width: String
  },
  components: {
    ChainTableCell
  },
  computed: {
    value() {
      if (this.metric.name == "Address") {
        return `${this.node.network_address}:${this.node.port}`
      } else {
        let alert = this
        .node
        .alerts
        .filter(alert => alert.metric == `${this.metric.module}/${this.metric.name}`)[0];

        if (!alert) {
          return "N/A";
        }

        let value = this.$store.state.metrics.metric[alert.id];
        if (!value) {
          return "N/A";
        }
        return formatMetricValue(this.metric.module, this.metric.name, value);
      }
    }
  },
};
</script>