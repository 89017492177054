<template>
  <v-container fill-height d-flex align-content-space-between>
    <v-row>
      <v-col class="pa-0">
        <v-list nav class="pb-0">
          <v-list-item link color="black" to="/">
            <v-list-item-icon>
              <v-icon color="black">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content><span style="font-family: Inter; font-weight: 600">Home</span></v-list-item-content>
          </v-list-item>
          <div v-for="chain in chains" v-bind:key="chain.id">
            <v-list-item link color="black" v-on:click="clickChain(chain.id, $event)">
              <v-list-item-icon>
                <v-img v-bind:src="chain.icon" max-height="24" max-width="24" contain></v-img>
              </v-list-item-icon>
              <v-list-item-title>
                <span style="font-family: Inter; font-weight: 600">{{
                  chain.name
                }}</span>
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="chainIsExpanded(chain.id)" color="black">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else color="black"> mdi-chevron-down </v-icon>
              </v-list-item-action>
            </v-list-item>
            <div v-if="chainIsExpanded(chain.id)">
              <v-list-item v-for="node in chain.nodes" v-bind:key="node.id" class="pl-16" link color="black"
                v-on:click="clickNode(chain.id, node.id)">
                <v-list-item-content>
                  <v-list-item-title>
                    <span style="font-family: Inter">{{ node.name }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="authStatus" class="pl-16" link color="black" v-on:click="$emit('addNewNode', chain.id)">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-list-item-icon class="ma-0" style="position: inline-block; align-self: center; vertical-align: middle; margin-right: 2px;">
                      <v-icon small style="color: black">mdi-plus</v-icon>
                    </v-list-item-icon>
                    <span style="font-family: Inter; font-weight: 400; position: inline-block; align-self: center; vertical-align: middle">Add Node</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <v-list-item v-if="authStatus" link color="black" to="/setup">
            <v-list-item-icon>
              <v-icon color="black">mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content><span style="font-family: Inter; font-weight: 600">Setup</span></v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="mt-2"></v-divider>
        <v-list nav v-if="authStatus">
          <v-list-item link color="black" v-on:click="$emit('addNewChain')">
            <v-list-item-icon>
              <v-icon style="color: black">mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><span style="font-family: Inter; font-weight: 600">Add Chain</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-list class="pa-0 pb-4">
          <v-list-item style="min-height: 28px">
            <div style="font-family: Inter">
              <small><version /> • <a href="https://github.com/eosphere/HeadsUp-Monitoring-Alerts" target="_blank" style="text-decoration: none; color: rgba(0, 0, 0, .87); font-weight: 600;">Github</a> • <a href="https://eosphere.io/" target="_blank" style="text-decoration: none; color: rgba(0, 0, 0, .87); font-weight: 600;">Website</a></small>
            </div>
          </v-list-item>
          <v-list-item style="min-height: 28px">
            <div style="font-family: Inter">
              <small>Copyright &copy; EOSphere Pty Ltd 2021-2024</small>
            </div>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Version from "./Version.vue";

export default {
  components: {
    Version,
  },
  data() {
    return {
      expandedChains: [],
    };
  },
  computed: {
    ...mapState({
      authStatus: (state) => state.auth.status,
      chains: (state) => state.chain.chains,
      ingestors: (state) => state.ingestors.ingestors,
    }),
  },
  methods: {
    chainIsExpanded(chainId) {
      return this.expandedChains.includes(chainId);
    },
    clickChain(chainId, event) {
      if (event.target.classList.contains("mdi-chevron-down")) {
        this.expandChainGroup(chainId);
      } else if (event.target.classList.contains("mdi-chevron-up")) {
        this.closeChainGroup(chainId);
      } else {
        this.toChain(chainId);
      }
    },
    clickNode(chainId, nodeId) {
      chainId = chainId.toString();
      nodeId = nodeId.toString();
      if (
        chainId == this.$route.params.chain &&
        chainId == this.$route.params.node
      )
        return;
      this.$router.push(`/node/${chainId}/${nodeId}`).catch(() => { });
    },
    clickIngestor(ingestorId) {
      let ingestorUrl = ingestorId.toString();
      if (ingestorUrl == this.$route.params.ingestor) return;
      this.$router.push("/ingestor/" + ingestorUrl);
    },
    expandChainGroup(chainId) {
      if (!this.expandedChains.includes(chainId)) {
        this.expandedChains.push(chainId);
      }
    },
    closeChainGroup(chainId) {
      if (this.expandedChains.includes(chainId)) {
        this.expandedChains = this.expandedChains.filter((chain) => {
          return chain != chainId;
        });
      }
    },
    toChain(chainId) {
      let chainUrl = `/chain/${chainId.toString()}`;
      console.log(chainUrl);
      this.$router.push(chainUrl).catch(() => { });
    },
  },
};
</script>

<style></style>