import Vue from 'vue';

export default Vue.mixin({
    methods: {
        toHumanTime(time) {
            const dateObject = new Date(time);
            const dateString = dateObject.toLocaleString("en-AU", {
              timeZone: "Australia/Perth",
            });
            return dateString;
        },
        toDateHumanFormat(time) {
            const dateObject = new Date(time);
            return dateObject.toLocaleDateString("en-AU", {
                timeZone: "Australia/Perth",
                day: 'numeric',
                month: 'long'
            });
        },
        toTimeHumanFormat(time) {
            const dateObject = new Date(time);
            return dateObject.toLocaleTimeString("en-AU", {
                timeZone: "Australia/Perth",
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
        },
        timeSince(time) {
            const now = new Date();
            const dateObject = new Date(time);
            const seconds = Math.floor((now - dateObject) / 1000);
        
            const intervals = [
                { label: 'year', seconds: 31536000 },
                { label: 'month', seconds: 2592000 },
                { label: 'day', seconds: 86400 },
                { label: 'hour', seconds: 3600 },
                { label: 'minute', seconds: 60 },
                { label: 'second', seconds: 1 }
            ];
            
            if (seconds >= 1) {
                for (let i = 0; i < intervals.length; i++) {
                    const interval = intervals[i];
                    const count = Math.floor(seconds / interval.seconds);
                    if (count >= 1) {
                        return `${count} ${interval.label}${count !== 1 ? 's' : ''}`;
                    }
                }
            } else {
                return "now";
            }
        },
        secondsToHuman(seconds) {
            const intervals = [
                { label: 'year', seconds: 31536000 },
                { label: 'month', seconds: 2592000 },
                { label: 'day', seconds: 86400 },
                { label: 'hour', seconds: 3600 },
                { label: 'minute', seconds: 60 },
                { label: 'second', seconds: 1 }
            ];
            
            if (seconds >= 1) {
                for (let i = 0; i < intervals.length; i++) {
                    const interval = intervals[i];
                    const count = Math.floor(seconds / interval.seconds);
                    if (count >= 1) {
                        return `${count} ${interval.label}${count !== 1 ? 's' : ''}`;
                    }
                }
            } else {
                return "now";
            }
        }
    }
});
