<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
  <v-row justify="center">
    <v-col cols="auto">
        <chain-table-edit
          v-bind:table="table"
          v-bind:chain="chain"
          v-on:confirm="$emit('close')"
          v-on:cancel="$emit('close')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChainTableEdit from "./Overlay/ChainTableEdit.vue";

export default {
  props: {
    table: Object,
    chain: Object,
  },
  components: {
    ChainTableEdit,
  },
  computed: {},
};
</script>

<style>
</style>