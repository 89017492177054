'use strict';

function formatChainsJsonAndBpJson(metricValue) {
	let values = {
		0: 'OK',
		1: 'Could not get Chains.json',
		2: 'Chain not in Chains.json',
		3: 'Could not get bp.json',
		4: 'Bp.json is not correctly formatted',
		5: 'Wrong Block Producer',
	};

	return values[metricValue];
}

function formatMetricValue(metricModule, metricName, metricValue) {
	let metricFullName = `${metricModule}/${metricName}`;
	switch (metricFullName) {
		case 'Node/Latency':
		case 'Hyperion/Latency':
		case 'AtomicAssets/Latency':
			return `${parseInt(metricValue)}ms`;
		case 'AtomicAssets/QueryTime':
			return `${(metricValue / 1000000).toFixed(0)}ms`;
		case 'Node/Connects':
		case 'History_Api/Status':
		case 'Hyperion/Connects':
		case 'AtomicAssets/Connects':
			return `${metricValue == 1 ? 'Yes' : 'No'}`;
		case 'Node/Head_Block':
		case 'Node/Chain_Id':
		case 'Node/Last_Irreversible_Block':
		case 'Node/Server_Version':
		case 'Node/Head_Block_Producer':
		case 'Node/Supported_Apis_Advertised':
		case 'Hyperion/Version':
		case 'Hyperion/Features':
		case 'Hyperion/StreamingFeatures':
		case 'Hyperion/TableFeatures':
		case 'Hyperion/IndexFeatures':
		case 'Hyperion/TrxFeatures':
		case 'Hyperion/ResourceFeatures':
		case 'Hyperion/RabbitMqStatus':
		case 'Hyperion/NodeosRPCStatus':
		case 'Hyperion/ElasticSearchStatus':
		case 'Hyperion/ElasticSearchLastIndexedBlock':
		case 'Hyperion/ElasticSearchHeadOffset':
		case 'Hyperion/ElasticSearchFirstIndexedBlock':
		case 'Hyperion/ElasticSearchMissingBlocks':
		case 'Hyperion/ElasticSearchTotalIndexedBlocks':
		case 'Hyperion/NodeosRPCBlockNumber':
		case 'AtomicAssets/Version':
		case 'AtomicAssets/AssetsVersion':
		case 'AtomicAssets/MarketVersion':
		case 'AtomicAssets/ToolsVersion':
		case 'AtomicAssets/PostgresStatus':
		case 'AtomicAssets/RedisStatus':
		case 'AtomicAssets/ChainStatus':
		case 'AtomicAssets/ChainHeadBlock':
		case 'AtomicAssets/BlocksDifference':
		case 'AtomicAssets/PostgresReaderBlockNumber':
		case 'Vote/Total':
		case 'Vote/Rank':
		case 'Vote/LastProducedBlockTime':
		case 'Hyperion/ElasticSearchActiveShards':
		case 'Hyperion/ElasticSearchMissingBlocksPercent':
			return `${metricValue}`;
		case 'Node/Db_Size':
		case 'Node/Db_Size_Free':
		case 'Node/Db_Size_Used':
			return `${parseFloatMetric(metricValue, 3)} MB`;
		case 'Website/ChainsJsonAndBpJson':
			return formatChainsJsonAndBpJson(metricValue);
		case 'Vote/Percentage':
			return `${metricValue}%`;
		case 'Vote/ActiveProducerHasProduced':
			return formatActiveProducerHasProducedMetricValue(metricValue);
		case 'Vote/LastProducedBlockNumber':
			return formatLastProducedBlockNumber(metricValue);
		case 'Node/Claimer_Has_Run':
			return formatClaimerHasRun(metricValue);
		default:
			return `Unknown metric ${metricFullName}`;
	}
}

function formatActiveProducerHasProducedMetricValue(metricValue) {
	if (typeof metricValue === "undefined") {
		return "undefined";
	}
	let intValue = parseInt(metricValue);
	if (intValue < 252) {
		return `Insufficent data ${intValue} block(s) 252 needed`;
	}
	if (intValue == 1000) {
		return "Not elected, cache OK";
	}
	if (intValue == 1001) {
		return "OK";
	}
	if (intValue >= 1100 && intValue <= 1111) {
		return `Error produced ${intValue - 1111} blocks produced 12 expected`;
	}
	return `Error - unknown value ${metricValue}`;
}

function formatLastProducedBlockNumber(metricValue) {
	if (typeof metricValue === "undefined") {
		return "undefined";
	}
	let intValue = parseInt(metricValue);
	if (intValue == 0) {
		return "N/A"
	}
	return metricValue;
}

function parseBlockProducerDateString(dateString) {
	// Extract date and time components from the string
	const [datePart, timePart] = dateString.split("T");
  
	// Extract year, month, and day from the date component
	const [year, month, day] = datePart.split("-").map(Number);
  
	// Extract hour, minute, second, and millisecond from the time component
	const [hour, minute, secondAndMilli] = timePart.split(":");
	const [second, millisecond] = secondAndMilli.split(".");
  
	// Create a new Date object with the extracted components in UTC
	const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second, millisecond));
  
	return utcDate;
}

function isWithinLast24HoursAnd10Minutes(date) {
	// Get the current time in milliseconds
	const currentTime = Date.now();

	// Calculate the time 24 hours and 10 minutes ago
	const timeThreshold = currentTime - (24 * 60 * 60 * 1000) - (10 * 60 * 1000);

	// Convert the input date to milliseconds
	const dateMilliseconds = date.getTime();

	// Compare the input date with the time threshold
	if (dateMilliseconds > timeThreshold) {
		return true;
	} else {
		return false;
	}
}

function formatClaimerHasRun(dateString) {
	if (!dateString || dateString == "") {
		return "No - Never run";
	}
	let dateTime = parseBlockProducerDateString(dateString);
	let isClaimOk = isWithinLast24HoursAnd10Minutes(dateTime);
	if (isClaimOk) {
		return `Yes at ${dateString}`;
	}
	else {
		return `No last run at ${dateString}`;
	} 
}

function parseFloatMetric(metricValue) {
	if (typeof metricValue === "undefined") {
		return "undefined";
	}
	return parseFloat(metricValue).toFixed(3);
}
  
module.exports = { formatMetricValue };
