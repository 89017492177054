<template>
    <v-chip
        v-if="headBlockProducer"
        color="black"
        outlined
        label
        class="mr-3"
        style="height: 36px; background-color: white !important"
    >
        Current Producer: {{ headBlockProducer }}
    </v-chip>
</template>

<script>
import { formatMetricValue } from "../../services/metricService";

export default {
    props: {
        blockProducer: Object,
    },
    computed: {
        headBlockProducer() {
            let alert = this.blockProducer
                .alerts
                .filter(alert => alert.metric == `Node/Head_Block_Producer`)[0];

            if (!alert) {
                return "N/A";
            }

            let value = this.$store.state.metrics.metric[alert.id];
            if (!value) {
                return "N/A";
            }
            return formatMetricValue("Node", "Head_Block_Producer", value);
        },
    }
}
</script>
