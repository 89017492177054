import Vue from 'vue';
import VueRouter from 'vue-router';
import Chain from '../views/Chain.vue';
import Node from '../views/Node.vue';
import Alerts from '../views/Alerts.vue';
import Alert from '../views/Alert.vue';
import Home from "../views/Home.vue";
import Ingestor from '../views/Ingestor.vue';
import Setup from '../views/Setup.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/alerts',
		name: 'Alerts',
		component: Alerts,
	},
	{
		path: '/alert',
		name: 'Alert',
		component: Alert,
	},
	{
		path: '/ingestor/:ingestor',
		name: 'Ingestor',
		component: Ingestor,
	},
	{
		path: '/chain/:chain',
		name: 'Chain',
		component: Chain,
	},
	{
		path: '/node/:chain/:node',
		name: 'Node',
		component: Node,
	},
	{
		path: '/setup',
		name: 'Setup',
		component: Setup,
	},
];

const router = new VueRouter({
	mode: 'history',
	/*base: '/app/' process.env.BASE_URL,*/
	routes,
});

export default router;
