'use strict';

/**
 * Flatten a list of lists and return it
 * @param {*[][]} a - A list of lists
 * @param {*[]} [r] - If provided this function will append to r, otherwise a new list is created.
 * @returns {*[]}
 */
 function flatten(a, r){
    // From: https://stackoverflow.com/a/16953805
    if(!r){ r = []}
    for(var i=0; i<a.length; i++){
        // Correct way to correct variable is an Array https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray
        if(Array.isArray(a[i])){
            r.concat(flatten(a[i], r));
        }else{
            r.push(a[i]);
        }
    }
    return r;
}

module.exports = { flatten }
