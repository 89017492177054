<template>
    <div>
        <v-list-item class="px-4 mb-0" v-on:click="toggle">
            <v-list-item-title>
                <span style="font-family: Inter; font-weight: 600">{{ title }} ({{ count }})</span>
            </v-list-item-title>
            <v-list-item-action>
                <v-icon v-if="open || query" color="black">
                    mdi-chevron-up
                </v-icon>
                <v-icon v-else color="black"> mdi-chevron-down </v-icon>
            </v-list-item-action>
        </v-list-item>
        <div v-if="open || query">
            <div>
                <connect-or-latency-alert
                    v-for="connectsAlert in alerts"
                    v-bind:key="connectsAlert.id"
                    v-bind:connectsAlert="connectsAlert"
                    v-on:update="updateAlertCondition" />
            </div>
        </div>
    </div>
</template>

<script>
import ConnectOrLatencyAlert from './ConnectOrLatencyAlert.vue'

export default {
    props: ['connectsAlerts', 'query'],
    components: {
        ConnectOrLatencyAlert
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        alerts() {
            return this.connectsAlerts.filter(alert => {
                return "advanced".includes(this.query.toLowerCase()) || 
                    alert.moduleMetric.Name.toLowerCase().includes(this.query.toLowerCase()) ||
                    alert.alert.url.toLowerCase().includes(this.query.toLowerCase());
            })
        },
        title() {
            return `Advanced Alerts`
        },
        count() {
            return this.connectsAlerts.length
        },
    },
    methods: {
        toggle() {
            this.open = !this.open
        },
        updateAlertCondition(alertId, condition) {
            this.$emit('update', alertId, condition)
        },
    },
};
</script>

<style></style>