<template>
  <v-card outlined light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: #0d53ee; text-align: left"
      class="pa-6"
      color="white"
    >
      <span
        style="
          font-family: Inter;
          color: white;
          font-size: 18px;
          line-height: 18px;
        "
      >
        <v-icon small color="white" style="margin-top: -5px">mdi-server</v-icon>
        Network
      </span>
    </v-card-title>
    <v-col class="pa-6 py-7">
      <v-row>
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 600"
            >IP Address</span
          ></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 400"
            >{{ nodeNetworkAddress }}</span
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 600"
            >Port</span
          ></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 400"
            >{{ nodePort }}</span
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 600"
            >Connection Type</span
          ></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 400"
            >{{ nodeConnectionType }}</span
          ></v-col
        >
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
export default {
  props: {
    node: Object,
  },
  computed: {
    nodeNetworkAddress() {
      return this.node ? this.node.network_address : null;
    },
    nodePort() {
      return this.node ? this.node.port : null;
    },
    nodeConnectionType() {
      return (this.node ? this.node.ssl : false) ? "Secure" : "Insecure";
    },
  },
};
</script>

<style>
</style>