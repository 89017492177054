'use strict';

const errorUtility = require("./errorUtility");

class WebSocketManager {
    constructor() {
        this.existingWebsocket = null;
        this.intervals = [];
    }

    setTimeout(fn, timeout) {
        // Wrap timeout in a method so we can mock it
        return setTimeout(fn, timeout);
    }

    clearTimeout(timeoutId) {
        clearTimeout(timeoutId);
    }

    newWebSocket(url) {
        return new WebSocket(url);
    }

    setInterval(fn, timeout) {
        // Wrap interval in a method so we can mock it
        return setInterval(fn, timeout);
    }

    clearInterval(intervalId) {
        clearInterval(intervalId);
    }

    _initWebsocket(url, timeoutMs) {
        timeoutMs = timeoutMs ? timeoutMs : 1500;
        var hasReturned = false;
        let self = this;
        var promise = new Promise((resolve, reject) => {
            self.setTimeout(function () {
                if(!hasReturned) {
                    hasReturned = true;
                    console.info('opening websocket timed out: ' + url);
                    errorUtility.default.reportMessageToSentry('_initWebsocket: opening websocket timed out: ' + url);
                    reject();
                }
            }, timeoutMs);
            if (!self.existingWebsocket || self.existingWebsocket.readyState != self.existingWebsocket.OPEN) {
                if (self.existingWebsocket) {
                    self.clearIntervals();
                    self.existingWebsocket.close();
                }
                var websocket = self.newWebSocket(url);
                websocket.onopen = function () {
                    if(hasReturned) {
                        websocket.close();
                    } else {
                        // console.info('websocket to opened! url: ' + url);
                        self.existingWebsocket = websocket;
                        hasReturned = true;
                        resolve(websocket);
                    }
                };
                websocket.onclose = function () {
                    // console.info('websocket closed! url: ' + url);
                    hasReturned = true;
                    reject();
                };
                websocket.onerror = function () {
                    console.info('websocket error! url: ' + url);
                    errorUtility.default.reportMessageToSentry('initWebsocket: websocket error! url: ' + url);
                    hasReturned = true;
                    reject();
                };
            } else {
                hasReturned = true;
                resolve(self.existingWebsocket);
            }
        });
        return promise;
    }
    
    /**
     * inits a websocket by a given url, returned promise resolves with initialized websocket, rejects after failure/timeout.
     *
     * @param url the websocket url to init
     * @param existingWebsocket if passed and this passed websocket is already open, this existingWebsocket is resolved, no additional websocket is opened
     * @param timeoutMs the timeout in milliseconds for opening the websocket
     * @param numberOfRetries the number of times initializing the socket should be retried, if not specified or 0, no retries are made
     *        and a failure/timeout causes rejection of the returned promise
     * @return {Promise}
     */
    async initWebsocket(url, timeoutMs, numberOfRetries) {
        timeoutMs = timeoutMs ? timeoutMs : 1500;
        numberOfRetries = numberOfRetries ? numberOfRetries : 0;
        if (numberOfRetries < 0) {
            throw new Error("Number of retries allowed exceeded.");
        }
        try {
            return await this._initWebsocket(url, timeoutMs);
        }
        catch (err) {
            if (numberOfRetries > 0) {
                return await this.initWebsocket(url, timeoutMs, numberOfRetries - 1);
            }
            else {
                errorUtility.default.reportExceptionToSentry(err);
                throw err;
            }
        }
    }

    startInterval(fn, timeout) {
        this.intervals.push(this.setInterval(fn, timeout));
    }

    clearIntervals() {
        this.intervals.forEach((interval) => this.clearInterval(interval));
        this.intervals = [];
    }
}

module.exports = { WebSocketManager }
