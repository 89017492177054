<template>
  <v-row
    v-on:click="openNode()"
    style="border-top: 0.6px solid rgba(0, 0, 0, 0.12); cursor: pointer"
    class="pa-3 flex-nowrap"
    align-content-center
  >
    <div
      class="py-1 pl-3"
      style="min-width: 360px; position: inline-block; align-self: center; vertical-align: middle"
    >
      <v-icon v-if="thisNodeStatus == 'active'" color="hsl(120, 67%, 39%)" size="14" style="margin-top: -1px">mdi-circle</v-icon>
      <v-icon v-else-if="thisNodeStatus == 'errors'" color="hsl(40, 87%, 49%)" size="14" style="margin-top: -1px">mdi-circle</v-icon>
      <v-icon v-else-if="thisNodeStatus == 'disconnected'" color="hsl(0, 87%, 59%)" size="14" style="margin-top: -1px">mdi-circle</v-icon>
      <v-icon v-else color="hsl(0, 0%, 59%)" size="14" style="margin-top: -1px">mdi-circle</v-icon>
      <span
        style="
          font-family: Inter;
          font-size: 0.875rem;
          line-height: 0.875rem;
          font-weight: 600;
          margin-left: 8px;
        "
        >{{ node.name }}</span
      >
      <v-chip
        outlined
        v-bind:color="nodeTypeColor"
        label
        class="ml-3"
        style="
          background-color: white !important;
          text-transform: capitalize;
          margin-top: -2px;
        "
      >
        {{ nodeType }}
      </v-chip>
    </div>
    <metric-value-chain-table-cell
      v-for="metric in activeMetrics(table)"
      v-bind:key="`${metric.module}/${metric.name}`"
      v-bind:width="metric.name == 'Address' ? '270px' : '180px'"
      v-bind:metric="metric"
      v-bind:node="node"
    />
    <div
      class="py-1 px-2"
      style="
        font-family: Inter;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        vertical-align: middle;
        text-align: right;
        color: #d0d0d0;
        height: auto;
        width: 90px;
        position: inline-block;
        align-self: center;
      "
    >
      {{ nodeLatency.toFixed(0) }}ms
    </div>
    <div
      class="py-1 px-3"
      style="
        font-family: Inter;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        vertical-align: middle;
        text-align: right;
        color: #d0d0d0;
        height: auto;
        width: 90px;
        position: inline-block;
        align-self: center;
      "
    >
      <v-btn v-on:click="openNode()" text icon color="black">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import MetricValueChainTableCell from "./MetricValueChainTableCell.vue"

import { mapGetters } from "vuex";
import { nodeTypeDictReversed } from "../../services/nodeTypeService";

export default {
  props: {
    node: Object,
    table: Object,
  },
  components: {
    MetricValueChainTableCell
  },
  computed: {
    ...mapGetters(["activeMetrics"]),
    thisNodeStatus() {
      return this.nodeStatus(this.node);
    },
    nodeLatency() {
      let latencyAlerts = this
        .node
        .alerts
        .filter(alert => alert.metric.endsWith("/Latency"));

      let latencyValues = latencyAlerts
            .map(alert => this.$store.state.metrics.metric[alert.id]);

      let highestValue = Math.max(...latencyValues
        .map(Number)
        .filter(val => !isNaN(val))
      );

      return highestValue;
    },
    nodeTypeColor() {
      let nodeType = this.node.type;
      if (nodeType == 0) return "#0d53ee";
      else if (nodeType == 1) return "#45e09e";
      else return "#479acc";
    },
    nodeType() {
      let nodeType = this.node ? this.node.type : null;
      if (nodeType in nodeTypeDictReversed) {
        return nodeTypeDictReversed[nodeType];
      }
      return "Unknown";
    },
  },
  methods: {
    openNode() {
      this.$router.push(`/node/${this.node.chain_id}/${this.node.id}`);
    },
  },
};
</script>