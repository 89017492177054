<template>
    <v-bottom-sheet v-if="sheet" v-model="sheet" persistent>
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="forceReload"
        >Update</v-btn>
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="ignoreUpgrade"
        >Ignore</v-btn>
        <div class="py-3">A new version {{version}} has been detected, do you want to upgrade?</div>
      </v-sheet>
    </v-bottom-sheet>    
</template>

<script>

import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            sheet: (state) => state.version.shouldUpgrade,
            version: (state) => state.version.version
        }),
    },
    methods: {
        ignoreUpgrade() {
            this.$store.dispatch('ignoreUpgrade');
        },
        forceReload() {
            window.location.reload(true);
        }
    }
}
</script>
