<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <ingestor-edit
          :mode="mode"
          :ingestor="ingestor"
          v-on:confirm="$emit('close')"
          v-on:cancel="$emit('close')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IngestorEdit from "./Overlay/IngestorEdit.vue";

export default {
  props: {
    mode: String,
    ingestor: Object,
  },
  components: {
    IngestorEdit,
  },
  computed: {},
};
</script>

<style>
</style>