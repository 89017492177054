<template>
    <v-list-item class="px-4 mb-0">
        <v-list-item-content class="py-0">
            <div style="width:100%; display:flex; justify-content:space-between; align-items:center;">
                <v-list-item-title>
                    <span style="font-family:Inter; margin-right:4px">{{ title }}</span>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="grey-lighten-2" v-bind="attrs" v-on="on" small
                                style="margin-top:-2px">mdi-help-circle-outline</v-icon>
                        </template>
                        <span>{{ description }}</span>
                    </v-tooltip>
                </v-list-item-title>
                <div>
                    <v-chip v-on:click="toggleMetric" light label outlined
                        v-bind:color="buttonEnabled ? 'green green--text' : 'grey grey--text'" class="rounded-pill px-2 py-0"
                        v-bind:style="buttonEnabled ? 'background-color:#eaf6ea!important; cursor:pointer;' : 'background-color:#f0f6f0!important; cursor:pointer;'">
                        {{ active ? 'Monitoring' : 'Monitor' }}
                    </v-chip>
                </div>
            </div>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: ['metric'],
    computed: {
        active() {
            return this.metric.configuration.monitoring;
        },
        buttonEnabled() {
            return this.metric.configuration.monitoring;
        },
        title() {
            return this.metric.information.Title;
        },
        description() {
            return this.metric.information.Description;
        }
    },
    methods: {
        toggleMetric() {
            if (!this.metric.information.Can_Manually_Enable) {
                return;
            }
            const metric = {
                Module: this.metric.information.Module,
                Name: this.metric.information.Name
            }
            if (this.active) {
                this.$emit('update', metric, {
                    alerting: false,
                    condition: "",
                    monitoring: false
                });
            } else {
                this.$emit('update', metric, {
                    alerting: false,
                    condition: "",
                    monitoring: true
                });
            }
        },
    },
}
</script>  