<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh;"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <metrics-and-alerts :node=node v-on:confirm="$emit('close')" v-on:cancel="$emit('close')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MetricsAndAlerts from './Overlay/MetricsAndAlerts.vue';

export default {
  props: {
    node: Object,
  },
  components: {
    MetricsAndAlerts,
  },
};
</script>

<style>
</style>