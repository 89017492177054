'use strict'

const nonNegativeInteger = v => {
    const pattern = /^\d*$/
    let result = pattern.test(v);
    return result || 'Must be a non-negative integer.'
};

const notEmpty = v => {
    return !!v || 'Must not be empty'
};

const notZero = v => {
    return !!v || 'Value must be chosen'
  }

export { nonNegativeInteger, notEmpty, notZero }