import Vue from 'vue';

export default Vue.mixin({
    methods: {
      getConnectionStatus(node) {
        const metrics = this.$store.state.metrics.metrics2.reduce((acc, moduleMetric) => {
          acc[`${moduleMetric.Module}/${moduleMetric.Name}`] = moduleMetric;
          return acc;
        }, {});

        let alerts = node.alerts.filter(alert => {
          return (["Connects", "ChainsJsonAndBpJson"].includes(metrics[alert.metric].Name) && metrics[alert.metric].Url_Paths.includes(alert.url)) ||
            alert.metric == "Node/Head_Block";
        });
        
        return !alerts.filter(alert => {
          return this.$store.state.notifications.notifications.filter(notification => {
            return notification.scope === "node" && notification.source === node.id && notification.alert_id === alert.id && !notification.archived && notification.notifications_count > 0;
          }).length;
        }).length;
      },
      nodeStatus(node) {
        if (!node) {
          return "not connected";
        }
        let connected = this.getConnectionStatus(node);
        if (!connected) {
          return "disconnected";
        } else if (this.nodeNotifications(node) > 0) {
          return "errors";
        } else {
          return "active";
        }
      },
        nodeNotifications(node) {
            return this.$store.state.notifications.notifications
            .filter((notification) => {
                return (
                  notification.scope === "node" &&
                  notification.source === node.id &&
                  !notification.archived &&
                  notification.notifications_count > 0
                );
            }).length;
        },
        nodeStatusBackgroundColor(nodeStatus) {
          if (nodeStatus == "active") return "hsl(120, 67%, 94%)"; // Light Green
          else if (nodeStatus == "errors") return "hsl(40, 87%, 94%)"; // Light Yellow
          else if (nodeStatus == "disconnected") return "hsl(0, 87%, 94%)"; // Light Red
          else if (nodeStatus == "not connected") return "hsl(0, 0%, 94%)"; // Light Grey
        },
        nodeStatusForegroundColor(nodeStatus) {
          if (nodeStatus == "active") return "hsl(120, 67%, 39%)"; // Green
          else if (nodeStatus == "errors") return "hsl(40, 87%, 49%)"; // Yellow
          else if (nodeStatus == "disconnected") return "hsl(0, 87%, 59%)"; // Red
          else if (nodeStatus == "not connected") return "hsl(0, 0%, 59%)"; // Grey
        }
    },
});
      