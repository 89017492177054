<template>
  <div
    class="py-1 px-2"
    v-bind:style="{
      minWidth: width,
    }"
    style="position: inline-block; align-self: center; vertical-align: middle;"
  >
    <span style="font-family: Inter; font-size: 0.875rem; font-weight: 400">{{
      value
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    width: String
  }
};
</script>