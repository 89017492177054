<template>
    <v-timeline-item right v-bind:color="accentColor" v-bind:icon="icon" fill-dot>
        <template v-slot:opposite>
            <span style="font-family: Inter; font-size: 12px;"><notification-time-since-cleared v-bind:notification="notification" /></span>
        </template>
        <template v-slot:default>
            <notification v-if="display == 'alerts'" v-on:click="openAlert(notification)" v-bind:notification="notification" display="historical" v-on:editAlert="editAlert"/>
            <notification v-else-if="display == 'alert'" v-on:click="openAlert(notification)" v-bind:notification="notification" display="alert" v-on:editAlert="editAlert"/>
        </template>
    </v-timeline-item> 
</template>

<script>
import Notification from "./Notification.vue";
import NotificationTimeSinceCleared from "./NotificationTimeSinceCleared.vue";

import { mapGetters } from "vuex";

import { encodeQuery } from '../../utils/queryParams';
import { formatMetricValue } from "../../services/metricService";

export default {
  components: {
    Notification,
    NotificationTimeSinceCleared,
  },
  props: {
    notification: Object,
    display: String,
  },
  data: () => ({
    showDetails: false
  }),
  computed: {
    ...mapGetters(["allIngestorIds", "allChainIds", "allNodeIds", "allIngestors", "allNodes"]),
    node() {
      if (this.scope == 'Node') {
        let search = this.allNodes.filter((node) => {
          return node.id == this.sourceId;
        })
        if (search.length > 0) {
          return search[0]
        }
      }
      return null;
    },
    chain() {
      if (this.node != null) {
        let search = this.$store.state.chain.chains.filter((chain) => {
          return chain.id == this.node.chain_id;
        })
        if (search.length > 0) {
          return search[0]
        }
      }
      return null;
    },
    alertId() {
      return this.notification?.id;
    },
    sourceId() {
      return this.notification?.source;
    },
    scope() {
      return this.notification?.scope.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    ingestorName() {
      return "Ingestor 1";
    },
    chainName() {
      return this.chain?.name;
    },
    nodeName() {
      return this.node?.name;
    },
    metricName() {
      return this.notification?.metric;
    },
    metricModule() {
      return this.metricName?.split('/')[0];
    },
    metricMetric() {
      return this.metricName?.split('/')[1];
    },
    metricValue() {
      return this.$store.state.metrics.metric[`${this.node.id}/${this.metricName}`];
    },
    alertText() {
      return this.notification?.text;
    },
    alertCreated() {
      return this.toDateHumanFormat(this.notification.time) + " " + this.toTimeHumanFormat(this.notification.time);
    },
    metricCurrent() {
      return formatMetricValue(
        this.metricModule,
        this.metricMetric,
        this.metricValue
      );
    },
    detailsSection() {
      return this.display!="home";
    },
    details() {
      if (this.scope == "Node") {
        return [
          {
            field: "Alert ID",
            value: this.alertId
          },
          {
            field: "Scope",
            value: this.scope
          },
          {
            field: "Chain",
            value: this.chainName
          },
          {
            field: "Node",
            value: this.nodeName
          },
          {
            field: "Metric",
            value: this.metricName
          },
          {
            field: "Alert",
            value: this.alertText
          },
          {
            field: "Alert Created",
            value: this.alertCreated
          },
          {
            field: "Current Value",
            value: this.metricCurrent
          },
        ];
      } else if (this.scope == "Ingestor") {
        return [
          {
            field: "Alert ID",
            value: this.alertId
          },
          {
            field: "Scope",
            value: this.scope
          },
          {
            field: "Ingestor",
            value: this.ingestorName
          },
          {
            field: "Alert",
            value: this.alertText
          },
          {
            field: "Alert Created",
            value: this.alertCreated
          },
        ];
      } else {
        return [];
      }
    },
    backgroundColor() {
      if (this.notification?.archived) return "hsl(0, 0%, 94%)";
      switch (this.notification?.type) {
        case "success":
          return "hsl(120, 67%, 94%)";
        case "information":
          return "hsl(220, 87%, 94%)";
        case "warning":
          return "hsl(40, 87%, 94%)";
        case "error":
          return "hsl(0, 87%, 94%)";
        default:
          return "hsl(0, 0%, 94%)";
      }
    },
    accentColor() {
      if (this.notification?.archived) return "hsl(0, 0%, 59%)";
      switch (this.notification?.type) {
        case "success":
          return "hsl(120, 67%, 39%)";
        case "information":
          return "hsl(220, 87%, 49%)";
        case "warning":
          return "hsl(40, 87%, 49%)";
        case "error":
          return "hsl(0, 87%, 59%)";
        default:
          return "hsl(0, 0%, 59%)";
      }
    },
    shadow() {
      if (this.notification?.archived) return "0px 0px 6px hsla(0, 0%, 59%, 0.75)";
      switch (this.notification?.type) {
        case "success":
          return "0px 0px 6px hsla(120, 87%, 39%, 0.75)";
        case "information":
          return "0px 0px 6px hsla(220, 87%, 49%, 0.75)";
        case "error":
          return "0px 0px 6px hsla(0, 87%, 59%, 0.75)";
        case "warning":
          return "0px 0px 6px hsla(39, 87%, 49%, 0.75)"
        default:
          return "0px 0px 6px hsla(0, 0%, 59%, 0.75)"
      }
    },
    icon() {
      switch (this.notification?.type) {
        case "success":
          return "mdi-check-circle-outline";
        case "information":
          return "mdi-information-slab-circle-outline";
        case "warning":
          return "mdi-alert-outline"
        case "error":
          return "mdi-alert-circle-outline";
        default:
          return "mdi-bell-outline"
      }
    }
  },
  methods: {
    goToSource() {
      if( this.notification.scope == "node" ) {
        this.$router.push(`/node/${this.node.chain_id}/${this.node.id}`).catch(() => {});
      } else if( this.notification.scope == "chain") {
        this.$router.push(`/chain/${this.notification.source}`).catch(() => {});
      }
    },
    goToNotification() {
      let queryParams = {
        metric: this.notification.metric,
        source: this.notification.source
      }
      this.$router.push(`/alert?${encodeQuery(queryParams)}`);
    },
    silenceAlert() {
      let alertSourceId = {
        metric: this.notification?.id,
        source: this.notification?.source
      };
      this.$emit('editAlert', alertSourceId)
    },
    editAlert(e) {
      this.$emit('editAlert', e)
    }
  },
};
</script>

<style>
.v-timeline::before {
  left: calc(0% - 1px + 144px) !important;
}
.v-timeline-item__body {
  max-width: calc(100% - 48px - 144px) !important;
}
.v-timeline-item__opposite {
  max-width: calc(0% - 48px + 144px) !important;
}
</style>