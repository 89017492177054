<template>
  <v-container class="pa-6">
    <v-row align="center">
      <v-col cols="auto" class="pr-0">
        <v-btn to="/alerts" icon outlined color="hsl(0, 0%, 59%)" style="background-color: hsl(0, 0%, 94%)">
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span style="font-family: Inter; text-transform: uppercase">
            Alert
          </span>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="pl-0">
        <v-btn
          v-on:click="editAlert"
          icon
          outlined
          color="hsl(0, 0%, 59%)"
          style="background-color: hsl(0, 0%, 94%)"
        >
          <v-icon small>mdi-bell-off</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-timeline class="mt-6" style="padding-top: 0px; padding-bottom: 0px">
      <timeline-item v-for="alert in alerts" v-bind:key="alert.id" v-bind:notification="alert" display="alert"/>
    </v-timeline>
  </v-container>
</template>

<script>
import TimelineItem from "../components/Notifications/TimelineItem.vue";

import { mapGetters, mapActions } from "vuex";
import { flatten } from "../utils/flatten"

export default {
  components: {
    TimelineItem
  },
  computed: {
    ...mapGetters({
      notifications: "allNotificationsByTimeRaised",
    }),
    alertId() {
      return this.$route.params.alert;
    },
    alerts() {
      return this.notifications.filter(alert => alert.metric == this.$route.query.metric && alert.source == this.$route.query.source);
    },
    alert() {
      let result = this.notifications ? this.notifications.filter(
        (notification) => notification.id == this.alertId
      )[0] : null;
      return result;
    },
    alertArchived() {
      return this.alert ? this.alert.archived : null;
    },
    alertType() {
      return this.alert ? this.alert.type : null;
    },
    alertText() {
      return this.alert ? this.alert.text : null;
    },
    alertTime() {
      return this.alert ? this.alert.time : 0;
    },
    activeNotifications() {
      return this.notifications.filter((notification) => {
        return !notification.archived;
      }).length;
    },
    successNotifications() {
      return this.notifications.filter((notification) => {
        return notification.type == "success" && !notification.archived;
      }).length;
    },
    warningNotifications() {
      return this.notifications.filter((notification) => {
        return notification.type == "warning" && !notification.archived;
      }).length;
    },
    errorNotifications() {
      return this.notifications.filter((notification) => {
        return notification.type == "error" && !notification.archived;
      }).length;
    },
    isSilenced() {
      let chains = this.$store.state.chain.chains;
      let nodes = flatten(chains.map(chain => chain.nodes));
      let alerts = flatten(nodes.map(node => node.alerts));
      let thisAlertSource = this.alert ? this.alert.source : null;
      let thisAlertMetric = this.alert ? this.alert.metric : null;
      let matchingAlerts = alerts.filter(alert => alert.node_id == thisAlertSource && alert.metric == thisAlertMetric);

      if (matchingAlerts.length == 0) {
        return "";
      }
      return matchingAlerts[0].is_silenced ? "Silenced" : "";
    }
  },
  methods: {
    ...mapActions(["archiveNotifications", "archiveAll"]),
    notificationTypeColor(alert) {
      let notificationType = alert ? alert.type : null;
      let notificationArchived = alert ? alert.archived : null;
      if (notificationArchived) return "grey";
      else if (notificationType == "success") return "green";
      else if (notificationType == "error") return "red";
      else if (notificationType == "warning") return "#f4b236";
      else return "grey";
    },
    getAlertType(alert) {
      return alert ? alert.type : null;
    },
    getAlertText(alert) {
      return alert ? alert.text : null;
    },
    getAlertTime(alert) {
      return alert ? alert.time : 0;
    },
    getIsSilenced(thisAlert) {
      let chains = this.$store.state.chain.chains;
      let nodes = flatten(chains.map(chain => chain.nodes));
      let alerts = flatten(nodes.map(node => node.alerts));
      let thisAlertSource = thisAlert ? thisAlert.source : null;
      let thisAlertMetric = thisAlert ? thisAlert.metric : null;
      let matchingAlerts = alerts.filter(alert => alert.node_id == thisAlertSource && alert.metric == thisAlertMetric);

      if (matchingAlerts.length == 0) {
        return "";
      }
      return matchingAlerts[0].is_silenced ? "Silenced" : "";
    },
    editAlert() {
      this.$emit('editAlert', {
        metric: this.$route.query.metric,
        source: this.$route.query.source
      });
    }
  },
};
</script>

<style>
.v-timeline-item:last-of-type {
  padding-bottom: 0px;
}
</style>