<template>
  <v-row class="pa-3" align-content-center>
    <div
      class="py-1 pl-3"
      style="width: 360px; position: inline-block"
      align-self="center"
    >
      <span
        style="
          font-family: Inter;
          font-size: 0.875rem;
          line-height: 0.875rem;
          font-weight: 600;
        "
        >Node</span
      >
    </div>
    <div
      v-for="metric in header"
      v-bind:key="metric.index"
      class="py-1 px-2"
      style="position: inline-block"
      v-bind:style="{
        width: `${metric.title == 'Address' ? '270px' : '180px'}`,
      }"
      align-self="center"
    >
      <span
        style="
          font-family: Inter;
          font-size: 0.875rem;
          line-height: 0.875rem;
          font-weight: 600;
        "
        >{{ metric.title }}</span
      >
    </div>
    <div
      class="py-1 px-2"
      style="width: 90px; position: inline-block"
      align-self="center"
    ></div>
  </v-row>
</template>

<script>
export default {
  props: {
    header: Array,
  }
}
</script>