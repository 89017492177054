<template>
  <v-container
    d-flex
    align-content-center
    fill-height
    fluid
    style="height: 100vh"
    class="pt-0"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <silence-all-alerts
          :alertSources="alertSources"
          :alertMetrics="alertMetrics"
          v-on:confirm="$emit('close')"
          v-on:cancel="$emit('close')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SilenceAllAlerts from "./Overlay/SilenceAllAlerts.vue";

export default {
  props: {
    alertSources: Array,
    alertMetrics: Array
  },
  components: {
    SilenceAllAlerts,
  },
  computed: {},
};
</script>

<style>
</style>