<template>
  <v-card width="640px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: black; text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px">mdi-key-plus</v-icon>
        Generate New Ingestor Access Key
      </span>
    </v-card-title>
    <v-col class="pa-6">
      <v-card-text class="pa-0" color="black" style="font-family: Inter;">
        <p>For your security, HeadsUp uses an access key to grant access to the data from your ingestor. In order to authenticate the connection, you will need to generate a key.</p>
        <p>⚠️ <strong>Important:</strong> Generating a new key for this ingestor will invalidate the previous key. To avoid temporary outages in monitoring, only generate a new key when necessary.</p>
      </v-card-text>
      <div style="text-align: right">
        <v-btn
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn"
          v-on:click="$emit('cancel')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Cancel</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          color="black"
          class="rounded-md ml-4 white--text pr-3"
          v-on:click="$emit('next')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Generate Key</span
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>