<template>
  <v-card outlined light class="rounded-md">
    <v-container style="background-color: #479acc">
      <v-row>
        <v-col cols="auto" class="pa-6" align-self="center">
          <v-card-title
            primary-title
            style="text-align: left"
            class="pa-0"
            color="white"
          >
            <span
              style="
                font-family: Inter;
                color: white;
                font-size: 18px;
                line-height: 18px;
              "
            >
              <v-icon small color="white" style="margin-top: -3px"
                >mdi-clock-outline</v-icon
              >
              Activity
            </span>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="false" cols="auto" class="px-6 py-3" align-self="center">
          <v-btn v-on:click="$emit('addNewIngestor')" text icon color="white">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-col class="pa-6 py-7">
      <activity-alert
        v-for="alert in activityAlerts"
        :alert="alert"
        :node="node"
        :key="alert.key"
      /> 
    </v-col>
  </v-card>
</template>

<script>
import ActivityAlert from "./ActivityAlert.vue";

import { isInformationMetric, isEndpointMetric } from "../../services/metricsCategorisation";

export default {
  props: {
    node: Object,
  },
  components: {
    ActivityAlert,
  },
  computed: {
    metrics() {
      let metrics = this.$store.state.metrics.metrics2;
      let enabledMetrics = this.node
        ? this.node.alerts.map((alert) => alert.metric)
        : null;
      enabledMetrics = new Set(enabledMetrics);
      return metrics.filter((metric) =>
        enabledMetrics.has(`${metric.Module}/${metric.Name}`)
      );
    },
    activityMetrics() {
      return this.metrics
        .filter(
          (metric) => !isInformationMetric(metric.Module, metric.Name) && !isEndpointMetric(metric.Module, metric.Name)
        )
        .sort((a, b) => a.Sort_Order - b.Sort_Order);
    },
    activityAlerts() {
      let metricsDict = this.$store.state.metrics.metricsDict;

      let alerts = this.node?.alerts ?? [];

      function sortAlerts(a, b) {
        if (a.metric.Sort_Order !== b.metric.Sort_Order) {
          return a.Sort_Order - b.Sort_Order;
        }
        if (a.url < b.url) return +1;
        if (a.url === b.url) return 0;
        if (a.url > b.url) return -1;
      }

      alerts = alerts.map(alert => 
      {
        let metric = metricsDict[alert.metric];
        return Object.assign(
          {},
          alert,
          {
            metric: metric,
            key: `${metric.Module}${metric.Name}${alert.url ?? ''}`
          });
      })
      .filter(
          (alert) => !isInformationMetric(alert.metric.Module, alert.metric.Name) 
          && !(isEndpointMetric(alert.metric.Module, alert.metric.Name) 
          && !["/v1/chain/get_info", "/v2/health", "/health"].includes(alert.url))
          && !(alert.metric.Name == "Latency"
          && !["/v1/chain/get_info", "/v2/health", "/health"].includes(alert.url))
      )
      .sort(sortAlerts);
      return alerts;
    }
  },
};
</script>