'use strict';

//From: https://stackoverflow.com/a/44622467
class DefaultDict {
    constructor(defaultInit) {
      return new Proxy({}, {
        get: (target, name) => name in target ?
          target[name] :
          (target[name] = typeof defaultInit === 'function' ?
            defaultInit() :
            defaultInit)
      })
    }
  }

  export default { DefaultDict }
