'use strict';

let chainData = [
	{
		name: 'Private Chain',
		icon: '/static/icons/chains/antelope.png',
		chain_id: '',
		vote_decay_type: 0,
		isReadOnly: false,
	},
	{
		name: 'EOS',
		icon: '/static/icons/chains/eos.png',
		chain_id:
			'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'Telos',
		icon: '/static/icons/chains/telos.png',
		chain_id:
			'4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11',
		vote_decay_type: 0,
		isReadOnly: true,
	},
	{
		name: 'WAX',
		icon: '/static/icons/chains/wax.png',
		chain_id:
			'1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
		vote_decay_type: 1,
		isReadOnly: true,
	},
	{
		name: 'Europechain',
		icon: '/static/icons/chains/europechain.png',
		chain_id:
			'f778f7d2f124b110e0a71245b310c1d0ac1a0edd21f131c5ecb2e2bc03e8fe2e',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'FIO',
		icon: '/static/icons/chains/fio.png',
		chain_id:
			'21dcae42c0182200e93f954a074011f9048a7624c6fe81d3c9541a614a88bd1c',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'Jungle 4',
		icon: '/static/icons/chains/jungle.png',
		chain_id:
			'73e4385a2708e6d7048834fbc1079f2fabb17b3c125b146af438971e90716c4d',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'Kylin',
		icon: '/static/icons/chains/kylin.png',
		chain_id:
			'5fff1dae8dc8e2fc4d5b23b2c7665c97f9e9d8edf2b6485a86ba311c25639191',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'Proton',
		icon: '/static/icons/chains/proton.png',
		chain_id:
			'384da888112027f0321850a169f737c33e53b388aad48b5adace4bab97f437e0',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'Remme',
		icon: '/static/icons/chains/remme.png',
		chain_id:
			'9f485317b61a19e956c822866cc57a64bbed2196e1cf178e80f847a139a20916',
		vote_decay_type: 2,
		isReadOnly: true,
	},
	{
		name: 'Libre',
		icon: '/static/icons/chains/libre.png',
		chain_id:
			'38b1d7815474d0c60683ecbea321d723e83f5da6ae5f1c1f9fecc69d9ba96465',
		vote_decay_type: 2,
		isReadOnly: true,
	},
];

function getChainMetaDataByName(chainName) {
	return chainData.filter((chain) => chain.name == chainName)[0];
}

function getChainMetaDataByChainId(chain_id) {
	return chainData.filter((chain) => chain.chain_id == chain_id)[0];
}

function getAllChainsMetaData() {
	return chainData;
}

export default {
	getChainMetaDataByName,
	getChainMetaDataByChainId,
	getAllChainsMetaData,
};
