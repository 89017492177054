export default {
	state: {
		ingestors: [
		]
	},
	getters: {
		allIngestorIds(state) {
			const ids = [];
			state.ingestors.forEach(ingestor => {
				ids.push(ingestor.id);
			});
			return ids;
		},
		allIngestors(state) {
			return state.ingestors;
		}
	},
	mutations: {
		initialiseIngestors(state, value) {
			state.ingestors = value;
		}
	},
	actions: {
		initialiseIngestors(context, ingestors) {
			context.commit('initialiseIngestors', ingestors);
		},
	},
	modules: {},
};
