import Vue from 'vue'
export default {
	state: {
        metric: [],
        metrics2: [],
		metricsDict: {}
	},
	getters: {
		getMetric: (state) => ({module:moduleName, metric:metricName}) => {
			let search = state.metrics2.filter((metric) => {
				return metric.Module === moduleName && metric.Name === metricName;
			})
			return search.length > 0 ? search[0] : null;
		},
		getMetricField: (state, getters) => (moduleMetric, field) => {
			let metric = getters.getMetric(moduleMetric);
			return metric ? metric[field] : null;
		}
	},
    mutations: {
		setMetric(state, data) {
            Vue.set(state.metric, `${data.alert_id}`, data.value);
		},
        initialiseMetrics2(state, value) {
            state.metrics2 = value;
            let metricsDict = Object.assign({},
                ...value.map(metric => ({[`${metric.Module}/${metric.Name}`]: metric})));
            state.metricsDict = metricsDict;
        }
	},
	actions: {
		setMetric(context, data) {
			context.commit('setMetric', data);
		},
        initialiseMetrics2(context, data) {
            context.commit('initialiseMetrics2', data);
        }
	},
	modules: {},
};
