<template>
  <v-card width="640px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: hsl(120, 67%, 39%); text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px">mdi-check</v-icon>
        Ingestor Access Key Generated
      </span>
    </v-card-title>
    <v-col class="pa-6">
      <v-card-text class="pa-0" color="black" style="font-family: Inter;">
        <p>Your new access key has been successfully created. Please follow the steps below to begin receiving data from your ingestor:</p>
        <p>
        <ol>
          <li><strong>Copy the key below:</strong> Use the "Copy" button below to capture the entire key.</li>
          <li>
            <strong>Update your ingestor config:</strong> Set this key as the value for <code>headsup_accesskey</code> in the Docker .yaml file.
            </li>
        <li><strong>Reload the ingestor:</strong> Reload your ingestor for the config changes to take effect.</li>
        </ol></p>

        <p>⚠️ <strong>Important:</strong> Your ingestor access key is not retrievable after this point. If you lose this key, you will need to generate a new one.</p>

      </v-card-text>
      <v-card-text class="pa-0" color="black">
        <span style="font-family: Inter; font-weight: 600">
          Access Key
        </span></v-card-text
      >
      <v-text-field
        id="accessKey"
        v-model="access_key"
        hide-details
        outlined
        dense
        light
        :readonly="true"
        color="black"
        class="mb-6"
        type="text"
        placeholder="Access Key"
      >
      </v-text-field>
      <div style="text-align: right">
        <v-btn 
          v-if="!copied"
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn pl-3"
          v-on:click="copyToClipboard"
        >
        <v-icon class="mr-1">mdi-link-variant</v-icon>
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Copy</span
          >
        </v-btn>
        <v-btn 
          v-else
          disabled
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn pl-3"
          v-on:click="$emit('cancel')"
        >
        <v-icon class="mr-1">mdi-check</v-icon>
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Copied</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          color="black"
          class="rounded-md ml-4 white--text pr-3"
          v-on:click="$emit('done')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Done</span
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
export default {
  props: {
    ingestor: Object,
    mode: String,
    access_key: String,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copyToClipboard() {
      this.copied = true;
      var copyText = document.getElementById("accessKey");
      copyText.select();
      document.execCommand("copy");
    }
  },
};
</script>

<style>
</style>