<template>
    <div>
        <v-list-item class="px-4 mb-0" v-on:click="toggle">
            <v-list-item-title>
                <span style="font-family: Inter; font-weight: 600">{{ title }} ({{ count }})</span>
            </v-list-item-title>
            <v-list-item-action>
                <v-icon v-if="open || query" color="black">
                    mdi-chevron-up
                </v-icon>
                <v-icon v-else color="black"> mdi-chevron-down </v-icon>
            </v-list-item-action>
        </v-list-item>
        <div v-if="open || query">
            <div>
                <alert v-for="metric in alerts" v-bind:key="metric.id" v-bind:metric="metric" v-on:update="updateMetric" />
            </div>
        </div>
    </div>
</template>

<script>
import Alert from './Alert.vue'

export default {
    props: ['metricModule', 'query'],
    components: {
        Alert
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        title() {
            return `${this.metricModule.description} Alerts`
        },
        alerts() {
            return Object.keys(this.metricModule.metrics).filter(metricKey => {
                return this.metricModule.metrics[metricKey].configuration.monitoring && this.metricModule.metrics[metricKey].information.Can_Alert
                    && (this.metricModule.name.toLowerCase().includes(this.query.toLowerCase()) || this.metricModule.metrics[metricKey].information.Name.toLowerCase().includes(this.query.toLowerCase()));
            }).sort((a, b) => {
                return this.metricModule.metrics[a].information.Title > this.metricModule.metrics[b].information.Title ? 1 : -1
            }).map(metricKey => this.metricModule.metrics[metricKey]);
        },
        count() {
            return Object.keys(this.alerts).length
        },
    },
    methods: {
        toggle() {
            this.open = !this.open
        },
        updateMetric(metric, configuration) {
            this.$emit('updateMetric', metric, configuration)
        },
    },
};
</script>

<style></style>