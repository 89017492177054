<template>
    <span>{{ timeSinceText }}</span>
</template>

<script>
export default {
  props: {
    notification: Object,
  },
  data: () => ({
    now: Date.now(),
  }),
  created() {
    var self = this
    setInterval(function () {
      self.now = Date.now();
    }, 1000);
},
  computed: {
    seconds() {
      return Math.floor((this.now - this.notification.cleared) / 1000);
    },
    timeSinceText() {
      const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
      ];

      if (this.seconds < 1) {
        return "now";
      }

      const interval = intervals.find(i => Math.floor(this.seconds / i.seconds) >= 1);
      const count = Math.floor(this.seconds / interval.seconds);

      return `${count} ${interval.label}${count !== 1 ? 's' : ''}`;
    }
  },
};
</script>