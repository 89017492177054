<template>
  <v-container class="pa-6">
    <v-alert
      v-if="ingestorConnectionError"
      text
      outlined
      type="error"
      class="mb-6"
    >
      You are not connected to an ingestor. Please check your internet
      connection and try again.
    </v-alert>
    <v-row align="center" style="margin-top: -12px">
      <v-col cols="auto" class="pr-0">
        <div class="text-h5">
          <span
            style="
              font-family: Inter;
              text-transform: uppercase;
              font-weight: 600;
            "
          >
            Home
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="chain in chains" :key="chain.id" cols="12" sm="12" lg="12" xl="6">
        <chain-overview-component
          :key="lastMessageId"
          v-bind:chain="chain"
          v-on:editChain="openChainEditOverlay"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import ChainOverviewComponent from "../components/Home/ChainOverview.vue";

export default {
  props: {
    ingestorConnectionError: Boolean,
  },
  components: {
    ChainOverviewComponent,
  },
  computed: {
    ...mapState({
      authStatus: (state) => state.auth.status,
      chains: (state) => state.chain.chains,
      ingestors: (state) => state.ingestors.ingestors,
    }),
    lastMessageId() {
      return this.$store.state.messages.lastMessageId;
    },
    notifications() {
      return this.$store.state.notifications.notifications
        .filter((notification) => {
          return notification.scope == "ingestor" && !notification.archived;
        })
        .reverse();
    },
  },
  methods: {
    openChainEditOverlay(chainId) {
      this.$emit("editChain", chainId);
    },
  },
};
</script>

<style>
</style>