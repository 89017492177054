<template>
    <v-chip
        v-if="voteRank"
        v-bind:color="voteRankColor"
        label
        class="mr-3"
        style="text-transform: capitalize; height: 36px"
    >
        Vote Rank: {{ voteRank }}
    </v-chip>
</template>

<script>
import { formatMetricValue } from "../../services/metricService";

export default {
    props: {
        blockProducer: Object,
    },
    computed: {
        voteRank() {
            let alert = this.blockProducer
                .alerts
                .filter(alert => alert.metric == `Vote/Rank`)[0];

            if (!alert) {
                return "N/A";
            }

            let value = this.$store.state.metrics.metric[alert.id];
            if (!value) {
                return "N/A";
            }
            return formatMetricValue("Vote", "Rank", value);
        },
        voteRankColor() {
            if (this.voteRank <= 21) return "#daa520";
            else if (this.voteRank <= 40) return "#c0c0c0";
            else return "#CD7F32";
        },
    }
}
</script>
