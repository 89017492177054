<template>
  <v-card width="350px" light class="rounded-md">
    <v-card-title
      primary-title
      style="background-color: black; text-align: left"
      class="pa-6"
      color="white"
    >
      <span style="font-family: Inter; color: white" class="text-h5">
        <v-icon color="white" style="margin-top: -5px"
          >mdi-shield-account</v-icon
        >
        Admin Access
      </span>
    </v-card-title>
    <v-col class="pa-6">
      <v-card-text class="pa-0 mb-6" color="black">
        <span style="font-family: Inter; font-weight: 400">
          You are currently logged in as admin
        </span></v-card-text
      >
      <div style="text-align: right">
        <v-btn
          large
          depressed
          text
          class="ml-4 rounded-md no-active-btn"
          v-on:click="$emit('cancel')"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Cancel</span
          >
        </v-btn>
        <v-btn
          depressed
          large
          color="black"
          class="rounded-md ml-4 white--text pr-3"
          v-on:click="callLogOut"
        >
          <span
            style="
              font-family: Inter;
              text-transform: none;
              font-weight: 600;
              margin-top: -3px;
            "
            >Sign out</span
          >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["logOut"]),
    callLogOut() {
      document.cookie = "access_token=;Path=/;expires=Sun, 20 Aug 2000 12:00:00 UTC";
      this.logOut();
      this.$emit("confirm");
    },
  },
};
</script>

<style>
</style>