<template>
  <v-card outlined light class="rounded-md">
    <v-container style="background-color: #479acc">
      <v-row>
        <v-col cols="auto" class="pa-6" align-self="center">
          <v-card-title
            primary-title
            style="text-align: left"
            class="pa-0"
            color="white"
          >
            <span
              style="
                font-family: Inter;
                color: white;
                font-size: 18px;
                line-height: 18px;
              "
            >
              <v-icon small color="white" style="margin-top: -3px"
                >mdi-cloud-outline</v-icon
              >
              API Endpoints
            </span>
          </v-card-title>
        </v-col>
      </v-row>
    </v-container>
    <v-col class="pa-6 py-7">
      <endpoint-metric
        v-for="alert in endpointAlerts"
        :alert="alert"
        :node="node"
        :key="alert.key"
      /> 
    </v-col>
  </v-card>
</template>

<script>
import EndpointMetric from "./EndpointMetric.vue";

import { isEndpointMetric } from "../../services/metricsCategorisation";

export default {
  props: {
    node: Object,
  },
  components: {
    EndpointMetric,
  },
  computed: {
    metrics() {
      let metrics = this.$store.state.metrics.metrics2;
      let enabledMetrics = this.node
        ? this.node.alerts.map((alert) => alert.metric)
        : null;
      enabledMetrics = new Set(enabledMetrics);
      return metrics.filter((metric) =>
        enabledMetrics.has(`${metric.Module}/${metric.Name}`)
      );
    },
    endpoints() {
      let metricsDict = this.$store.state.metrics.metricsDict;
      let alerts = this.node?.alerts ?? [];
      function sortAlerts(a, b) {
        if (a.metric.Sort_Order !== b.metric.Sort_Order) {
          return a.Sort_Order - b.Sort_Order;
        }
        if (a.url < b.url) return +1;
        if (a.url === b.url) return 0;
        if (a.url > b.url) return -1;
      }
      alerts = alerts.map(alert => 
      {
        let metric = metricsDict[alert.metric];
        return Object.assign(
          {},
          alert,
          {
            metric: metric,
            key: `${metric.Module}${metric.Name}${alert.url ?? ''}`
          });
      })
      .filter(
          (alert) => isEndpointMetric(alert.metric.Module, alert.metric.Name)
      )
      .sort(sortAlerts);
      let filteredAlerts = alerts.filter(
          (alert) => isEndpointMetric(alert.metric.Module, alert.metric.Name)
      )
      let mappedAlerts = filteredAlerts.map(
        (alert) => {
        return {
          module: alert.metric.Module,
          url: alert.url
        }
      })
      return mappedAlerts;
    },
    endpointAlerts() {
      let metricsDict = this.$store.state.metrics.metricsDict;

      let alerts = this.node?.alerts ?? [];

      function sortAlerts(a, b) {
        if (a.metric.Sort_Order !== b.metric.Sort_Order) {
          return a.Sort_Order - b.Sort_Order;
        }
        if (a.url < b.url) return +1;
        if (a.url === b.url) return 0;
        if (a.url > b.url) return -1;
      }

      alerts = alerts.map(alert => 
      {
        let metric = metricsDict[alert.metric];
        return Object.assign(
          {},
          alert,
          {
            metric: metric,
            key: `${metric.Module}${metric.Name}${alert.url ?? ''}`
          });
      })
      .filter(
          (alert) => isEndpointMetric(alert.metric.Module, alert.metric.Name)
      )
      .sort(sortAlerts);
      return alerts;
    }
  },
};
</script>