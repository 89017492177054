<template>
  <v-card outlined light class="rounded-md">
    <v-card-title
      primary-title
      color="black"
      class="pa-0"
      style="text-align: left"
      v-bind:style="{
        backgroundColor: thisChainStatusBackgroundColor,
      }"
    >
      <v-container class="pa-6">
        <v-row align="center">
          <v-col cols="auto" class="py-3 pr-1">
            <v-img
              v-if="this.chain"
              v-bind:src="this.chain.icon"
              max-height="24"
              max-width="24"
              contain
            ></v-img>
          </v-col>
          <v-col cols="auto" class="py-3 pl-1">
            <span
              v-if="this.chain"
              style="
                font-family: Inter;
                font-size: 18px;
                line-height: 18px;
                font-weight: 600;
              "
              v-bind:style="{
                color: thisChainStatusForegroundColor,
              }"
            >
              {{ this.chain.name }}
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-chip v-if="thisActiveNodes > 0" light label outlined class="rounded-pill"
              style="height: 36px; background-color: hsl(120, 67%, 94%) !important; color: hsl(120, 67%, 39%) !important; border-color: hsl(120, 67%, 39%) !important;">
              <v-icon color="hsl(120, 67%, 39%)" class="mr-1">mdi-circle</v-icon>
              <span style="font-family: Inter; color: hsl(120, 67%, 39%)">
                {{ thisActiveNodes }} Active
              </span>
            </v-chip>
            <v-chip v-if="thisErrorsNodes > 0" light label outlined class="rounded-pill ml-3"
              style="height: 36px; background-color: hsl(40, 87%, 94%) !important; color: hsl(40, 87%, 49%) !important; border-color: hsl(40, 87%, 49%) !important;">
              <v-icon color="hsl(40, 87%, 49%)" class="mr-1">mdi-circle</v-icon>
              <span style="font-family: Inter; color: hsl(40, 87%, 49%)">
                {{ thisErrorsNodes }} With Errors
              </span>
            </v-chip>
            <v-chip v-if="thisDisconnectedNodes > 0" light label outlined class="rounded-pill ml-3"
              style="height: 36px; background-color: hsl(0, 87%, 94%) !important; color: hsl(0, 87%, 59%) !important; border-color: hsl(0, 87%, 59%) !important;">
              <v-icon color="hsl(0, 87%, 59%)" class="mr-1">mdi-circle</v-icon>
              <span style="font-family: Inter; color: hsl(0, 87%, 59%)">
                {{ thisDisconnectedNodes }} Disconnected
              </span>
            </v-chip>
            <v-chip v-if="thisNotConnectedNodes > 0" light label outlined class="rounded-pill ml-3"
              style="height: 36px; background-color: hsl(0, 0%, 94%) !important; color: hsl(0, 0%, 59%) !important; border-color: hsl(0, 0%, 59%) !important;">
              <v-icon color="hsl(0, 0%, 59%)" class="mr-1">mdi-circle</v-icon>
              <span style="font-family: Inter; color: hsl(0, 0%, 59%)">
                {{ thisNotConnectedNodes }} Not Connected
              </span>
            </v-chip>
          </v-col>
          <v-col v-if="authStatus" cols="auto" class="pl-0">
            <v-btn
              v-on:click="$emit('editChain', chain.chain_id)"
              icon
              outlined
              color="grey"
              style="backgroundcolor: #fff"
            >
              <v-icon small>mdi-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-divider></v-divider>
    <v-col class="pa-6 py-7">
      <v-row v-for="node in nodes" v-bind:key="node.id" v-on:click="openNode(node)" style="cursor: pointer;">
        <v-col cols="auto" class="py-1"
          ><span
            style="font-family: Inter; font-size: 0.875rem; font-weight: 600"
            >{{ node.name }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="py-1">
          <chain-overview-node-status v-bind:node="node"/>
        </v-col>
      </v-row>
    </v-col>
    <v-divider v-if="thisChainNotifications.length > 0"></v-divider>
    <v-col v-if="thisChainNotifications.length > 0" class="pa-6 pb-0">
      <notification v-on:click="openAlert(notification)" v-for="notification in thisChainNotifications" v-bind:notification="notification" display="home" v-bind:key="notification.id" v-on:editAlert="editAlert" class="mb-6"/>
    </v-col>
  </v-card>
</template>

<script>
import Notification from "../../components/Notifications/Notification.vue"
import ChainOverviewNodeStatus from "./ChainOverviewNodeStatus.vue"

import { mapGetters } from "vuex";

import { EditDialogModeEnum } from "../../enums/editDialogModeEnum";

export default {
  components: {
    Notification,
    ChainOverviewNodeStatus
  },
  props: {
    chain: Object,
  },
  data: () => ({
    overlay: false,
    overlayMode: null,
  }),
  computed: {
    ...mapGetters({
      notifications: "allNotificationsByTimeRaised",
    }),
    nodes() {
      return this.chain.nodes;
    },
    thisChainNotifications() {
      return this.chainNotifications(this.chain);
    },
    thisActiveNodes() {
      return this.activeNodes(this.chain);
    },
    thisErrorsNodes() {
      return this.errorsNodes(this.chain);
    },
    thisDisconnectedNodes() {
      return this.disconnectedNodes(this.chain);
    },
    thisNotConnectedNodes() {
      return this.notConnectedNodes(this.chain);
    },
    thisChainStatus() {
      return this.chainStatus(this.chain);
    },
    thisChainStatusBackgroundColor() {
      return this.chainStatusBackgroundColor(this.chain);
    },
    thisChainStatusForegroundColor() {
      return this.chainStatusForegroundColor(this.chain);
    },
    authStatus() {
      return this.$store.state.auth.status;
    },
  },
  methods: {
    openAlert(id) {
      this.$router.push(`/alert/${id}`);
    },
    openChainOverlay() {
      this.overlayMode = EditDialogModeEnum.Edit;
      this.overlay = true;
    },
    editAlert(e) {
      this.$emit('editAlert', e)
    },
    openNode(node) {
      this.$router.push(`/node/${node.chain_id}/${node.id}`);
    },
  },
};
</script>